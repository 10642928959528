import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BsEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import Wrapper from "../components/Wrapper";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { userLoginReducer } from "../redux/reducers/userReducer";
import { userLoginAction } from "../redux/actions/userAction";
import GoogleLogin from "./GoogleLogin";
import { login } from "../utils/api";

const LoginScreen = ({ location }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  // const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));

  let state = useSelector((state) => {
    return state;
  });
  const history = useHistory();

  let dispatch = useDispatch();

  const { email, password } = inputs;
  const [emailChk, setEmailChk] = useState()
  const onChangeHandler = (e, type) => {
    // if(type === 'email'){
    //   const emailRegex =
    //   /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    //   const emailCurrent = e.target.value;
    //   if (!emailRegex.test(emailCurrent)) {
    //     setEmailChk(false)
    //   }else{
    //     setEmailChk(true);
    //   }
    //   setInputs({
    //     ...inputs,
    //     [e.target.name]: e.target.value,
    //   });
    // }else{
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    // }
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    //console.log("submit");

    let body = {
      username: inputs.email,
      password: inputs.password,
    };
    
    if(inputs.password && inputs.email){
      login(body).then((res)=>{
        if(res.data.value === '10'){
          alert('존재하지 않는 계정 입니다.');
        }
        else if(res.data.value === '7'){
          alert('탈퇴한 유저입니다.');
        }
        else if(res.data.value === '9'){
          alert('아이디 또는 비밀번호가 일치하지 않습니다.');
        }
        else{
          localStorage.setItem("userinfo", JSON.stringify(res.data.user));
          dispatch(userLoginAction());
          // alert('로그인되었습니다!');
          history.replace("/");
          window.location.reload();
        }
      }).catch((e)=>{
        console.log(e)
      })
    }else if(inputs.email){
      alert('비밀번호를 입력해 주세요.');
      document.getElementById('password').focus();
    }else{
      alert('아이디를 입력해 주세요.');
      document.getElementById('email').focus();
    }

  };

  return (
    <Wrapper className="flex justify-center items-center px-6">
      <div className="max-w-[574px] w-full border border-gray-300 rounded-sm shadow-custom">
        <div className="flex justify-center text-lg py-4">{t("login")}</div>
        <div className="flex flex-col justify-center items-center border-t border-t-gray-300 p-4">
          <p className="mt-2 text-sm text-gray-500 text-center">
            {t("Please check the address bar for valuable information")}
          </p>
          <p className="w-full mt-4 text-[11px] text-gray-500 border border-gray-200 rounded-full py-2 text-center">
            {t("login.msg")}
          </p>

          <form className="w-full mt-12" onSubmit={onSubmitHandler}>
            <div className="border border-gray-300 rounded-sm mb-4 ">
              <input
                id="email"
                type="text"
                name="email"
                defaultValue={email}
                placeholder={'ID'}
                onChange={(e)=>onChangeHandler(e,'email')}
                className="w-full focus:outline-none focus:border-sky-600 px-3 py-2 text-sm placeholder:text-gray-600"
              />
            </div>
            {/* 이메일체크 */}
            {/* <div className="mb-1 text-red-400 text-xs px-1">{inputs.email.length === 0 || emailChk?
            '\0':'이메일형식에 맞게 적어주세요'}</div> */}
            <div className="border border-gray-300 rounded-sm flex justify-between items-center mb-12">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                defaultValue={password}
                placeholder={t("password")}
                onChange={(e)=>onChangeHandler(e,'')}
                className="w-full focus:outline-none focus:border-sky-600 px-3 py-2 text-sm placeholder:text-gray-600"
              />
              {/* 비밀번호 보이게 하는 버튼 */}
              {showPassword ? (
                <button type="button" onClick={() => setShowPassword(false)}>
                  <IoEyeSharp className="mr-4 text-2xl text-gray-500" />
                </button>
              ) : (
                <button type="button" onClick={() => setShowPassword(true)}>
                  <BsEyeSlashFill className="mr-4 text-2xl text-gray-500" />
                </button>
              )}
            </div>
            {/* 로그인 버튼 */}
            <button
              type="submit"
              className="w-full bg-gray-500 text-white text-sm font-bold rounded-sm py-[10px]"
            >
              {t("login")}
            </button>
          </form>
          {/* 구글 로그인 버튼 */}
          {/* <button
            type="button"
            className="w-full flex justify-center items-center bg-gray-200 py-2 space-x-2 rounded-sm my-6"
          >
            <FcGoogle className="text-2xl" />
            <span>{t("login")}</span>
          </button> */}
          {/* <GoogleLogin /> */}
          {/* <div className="w-full flex justify-end items-center space-x-4 text-sm my-6">
            <Link to="/password_reset" className="text-gray-500">
              {t("Reset your password")}
            </Link>
            <Link to="/signup">{t("Join")}</Link>
          </div> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default LoginScreen;
