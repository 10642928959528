import React, { useState, useEffect, useRef } from "react";
import { BsEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5";
import Wrapper from "../components/Wrapper";
import { lowercase, number, uppercase } from "../utils/regex";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import { signup_web, checkEmail } from "../utils/api";
import $ from "jquery";
import jsSHA from "jssha";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { API_URL2, getUser } from "../utils/api";

// import { useTranslation } from "react-i18next";
// useState으로 상태관리를 하지 않고 let 변수를 통해 값 변경 -> 그러면 위에 나머지 값들도 전부 변수로?
let status = false;
let pwCheck = false;
let pwConfCheck = false;
const RegisterScreen = () => {
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [uppercaseRegex, setUppercaseRegex] = useState(false);
  const [lowercaseRegex, setLowercaseRegex] = useState(false);
  const [numberRegex, setNumberRegex] = useState(false);
  const [atLeast10Regex, setAtLeast10Regex] = useState(false);
  const [inputEmail, setinputEmail] = useState(false);
  const [OTP, setOTP] = useState('');
  const [OTPInput, setOTPInput] = useState('');
  const [OTPCode, setOTPCode] = useState('');
  const [OTPChk, setOTPChk] = useState(false);
  const intervalRef = useRef(null);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [recommendCode, setRecommendCode] = useState("");
  const [supportCode, setSupportCode] = useState("");
  const [language, setLanguage] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAddr, setBankAddr] = useState("");
  
  const [isRecommand, setIsRecommand] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  
  const [depositor, setDepositor] = useState("");
  const [bankNameSuggestions, setBankNameSuggestions] = useState([]);
  const [selectedBankName, setSelectedBankName] = useState(""); // 선택된 값 저장
  
  const [centerType, setCenterType] = useState('1');
  const [center, setCenter] = useState({
    name : '',
    address : '',
    group : '',
  });

  const [group, setGroup] = useState({
    name : '',
    address : '',
    group : '',
  });

  useEffect(()=>{
    const lang = window.navigator.language.split('-')
    setLanguage(lang[0] || 'ko');
    
    var ar = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
    var secretVal = '';
    for (var i = 0; i < 16; i++) {
      secretVal += ar.charAt(Math.floor(Math.random() * ar.length));
    };
    setOTPCode(secretVal)
    updateOtp(secretVal);
  
    intervalRef.current = setInterval(()=>{
      updateOtp(secretVal)
    }, 1000);

    // 컴포넌트가 언마운트될 때 인터벌 정리
    return () => clearInterval(intervalRef.current);
  },[])

  useEffect(()=>{
    setCenter({
      name : '',
      address : '',
      group : '',
    })
    setGroup({
      name : '',
      address : '',
      group : '',
    })
  },[centerType])
  const passwordHandler = (e) => {
    setPassword(e.target.value);
    // 대문자 포함
    // if (uppercase(e.target.value)) {
    //   setUppercaseRegex(true);
    // } else {
    //   setUppercaseRegex(false);
    // }
    // 소문자 포함
    if (lowercase(e.target.value)) {
      setLowercaseRegex(true);
    } else {
      setLowercaseRegex(false);
    }
    // 숫자 포함
    if (number(e.target.value)) {
      setNumberRegex(true);
    } else {
      setNumberRegex(false);
    }
    // 10자 이상
    if (e.target.value.length >= 10) {
      setAtLeast10Regex(true);
    } else {
      setAtLeast10Regex(false);
    }
  };
  // const { t } = useTranslation();
  const [emailChk, setEmailChk] = useState()
  const [lbankAddr, setLbankAddr] = useState();
  const emailHandler = (e) => {
    // const emailRegex =
    //   /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    //   const emailCurrent = e.target.value;
    //   if (!emailRegex.test(emailCurrent)) {
    //     setEmailChk(false)
    //   }else{
    //     setEmailChk(true);
    //   }
    setEmail(e.target.value);
  };
  const passwordConfirmHandler = (e) => {
    setPasswordConfirm(e.target.value);
  };

  const otpHandler = (e) => {
    setOTPInput(e.target.value);
  }

  const checkOTP = () => {
    if(OTP === OTPInput){
      setOTPChk(true);
    }
    else{
      alert('인증정보가 일치 하지 않습니다.');
    }
  }

  const handlLbank = (e) => {
    setLbankAddr(e.target.value);
  }

  const history = useHistory();

  const emailCheck = () => {
    if(email){
      const body = {
        email: email,
      };
      checkEmail(body).then((res)=>{
        if (res.data.value === "1") {
          status = true;
          //console.log(status);
          setinputEmail(true);
          alert(t("Email available"));
        } else if (res.data.value === "0") {
          status = false;
          //console.log(status);
          alert(t("This email is associated to an existing account"));
        }
      }).catch(res=>{console.log(res)})
    }else{
      alert('아이디를 입력해주세요!');
      document.getElementById('email').focus();
    }
    // axios
    //   .post("http://3.35.221.77:7272/reactEmailCheck", body)
    //   .then((result) => {
    //     //console.log(result.data.value);
    //     if (result.data.value === "1") {
    //       status = true;
    //       //console.log(status);
    //       setinputEmail(true);
    //       alert(t("Email available"));
    //     } else if (result.data.value === "0") {
    //       status = false;
    //       //console.log(status);
    //       alert(t("This email is associated to an existing account"));
    //     }
    //   })
    //   .catch((error) => {
    //     //console.log(error);
    //   });
  };

  const onSubmitHandler = (e) => {
    lowercaseRegex && numberRegex && atLeast10Regex
      ? (pwCheck = true)
      : (pwCheck = false);
    password === passwordConfirm ? (pwConfCheck = true) : (pwConfCheck = false);
    // TODO: 회원가입 진행

    if (pwCheck && status && pwConfCheck && OTPCode && OTPChk && name && phone) {
      if(recommendCode && !isRecommand){
        return alert('추천아이디 확인을 해주세요')
      }
      if(supportCode && !isSupport){
        return alert('후원아이디 확인을 해주세요')
      }
        const body = {
          username: email,
          password: passwordConfirm,
          name : name,
          phone : phone,
          recommendCode : recommendCode,
          supportCode : supportCode,
          language : language,
          otpCode: OTPCode,
          bankName: bankName,
          bankAddr: bankAddr.replace(/[-\s]/g, ''),
          depositor: depositor,
          centerName : center.name,
          centerAddr : center.address,
          groupName : center.group,
        };
        console.log('body >>>', body)
        signup_web(body).then((res)=>{
          console.log(res)
          if (res.data.value === "1") {
            history.push("/login");
            alert(t("Registration successful"));
          } else if (res.data.value === "0") {
            alert(t("Registration failed"));
          }
        })
    } else {
      if (!pwConfCheck) {
        alert(t("Passwords do not match"));
      } else if (!status) {
        alert(t("Verify your email"));
      }
      //console.log("실패", pwCheck, status, pwConfCheck);
      alert(t("Registration failed"));
    }
  };

  function updateOtp(secretVal) {
    var key = base32tohex(secretVal);
    var epoch = Math.round(new Date().getTime() / 1000.0);
    var time = leftpad(dec2hex(Math.floor(epoch / 30)), 16, '0');

    // updated for jsSHA v2.0.0 - http://caligatio.github.io/jsSHA/
    var shaObj = new jsSHA("SHA-1", "HEX");
    shaObj.setHMACKey(key, "HEX");
    shaObj.update(time);
    var hmac = shaObj.getHMAC("HEX");

    if (hmac == 'KEY MUST BE IN BYTE INCREMENTS') {
        $('#hmac').append($('<span/>').addClass('label important').append(hmac));
    } else {
        var offset = hex2dec(hmac.substring(hmac.length - 1));
        var part1 = hmac.substr(0, offset * 2);
        var part2 = hmac.substr(offset * 2, 8);
        var part3 = hmac.substr(offset * 2 + 8, hmac.length - offset);
        if (part1.length > 0) $('#hmac').append($('<span/>').addClass('label label-default').append(part1));
        $('#hmac').append($('<span/>').addClass('label label-primary').append(part2));
        if (part3.length > 0) $('#hmac').append($('<span/>').addClass('label label-default').append(part3));
    }

    var otp = (hex2dec(hmac.substr(offset * 2, 8)) & hex2dec('7fffffff')) + '';
    otp = (otp).substr(otp.length - 6, 6);
    setOTP(otp);
    // console.log(otp)
  }

  function dec2hex(s) { return (s < 15.5 ? '0' : '') + Math.round(s).toString(16); }
  function hex2dec(s) { return parseInt(s, 16); }

  function base32tohex(base32) {
      var base32chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
      var bits = "";
      var hex = "";
      if (base32 === "" && base32 === "undefined" && base32 === null) {

      } else {
          for (var i = 0; i < base32.length; i++) {
              var val = base32chars.indexOf(base32.charAt(i).toUpperCase());
              bits += leftpad(val.toString(2), 5, '0');
          }

          for (var i = 0; i + 4 <= bits.length; i += 4) {
              var chunk = bits.substr(i, 4);
              hex = hex + parseInt(chunk, 2).toString(16);
          }
          return hex;
      }
  }

  function leftpad(str, len, pad) {
      if (len + 1 >= str.length) {
          str = Array(len + 1 - str.length).join(pad) + str;
      }
      return str;
  }

  const checkRecommand = () => {
    $.ajax({ 
      url: `${API_URL2}/recommendCheck`, 
      type: "POST", 
      data: { 'recommendCode': recommendCode}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        console.log(result)
        if(result.code === '1'){
          setIsRecommand(true)
        }
        else{
          alert('존재하지 않는 아이디입니다.')
          setIsRecommand(false)
        }
      }, error: function (error) { 
        console.log(error)
      } 
    })
  }
  
  const checkSupport = () => {
    $.ajax({ 
      url: `${API_URL2}/supportCheck`, 
      type: "POST", 
      data: { 'supportCode': supportCode}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        console.log(result)
        if(result.code === '1'){
          setIsSupport(true)
        }
        else{
          alert('존재하지 않는 아이디입니다.')
          setIsSupport(false)
        }
      }, error: function (error) { 
        console.log(error)
      } 
    })
  }


  const bankNameKeyUp = (text) => {
    console.log("text >>", text)
    // const currentValue = e.target.value;
    // console.log("currentValue", currentValue)
    if(text) {
      $.ajax({ 
        url: `${API_URL2}/bank_name_list`, 
        type: "POST", 
        data: { 'text': text}, 
        dataType: "json", 
        async: true, 
        success: function (result) { 
          setBankNameSuggestions(result.bank_list);
  
        }, error: function (error) { 
          console.log(error)
        } 
      })
    } else {
      setBankNameSuggestions([]);
    }

  }

  const handleSuggestionClick = (suggestion) => {
    setSelectedBankName(suggestion); // 선택된 항목을 저장
    setBankName(suggestion)
    setBankNameSuggestions([]); // 리스트 초기화 (숨기기)
  };




  return (
    <Wrapper className="min-h-screen flex justify-center items-center px-6">
      <div className="max-w-[574px] w-full border border-gray-300 rounded-sm shadow-custom">
        <div className="flex justify-center text-base py-4">{t("Join")}</div>
        <div className="flex flex-col justify-center items-start p-4 border-t border-t-gray-300">
          <div className="w-full">

            <div className="w-full mb-3">
              <label className="text-sm text-gray-500">{t("signUpName")}</label>
              <div className="border border-gray-300 rounded-sm mt-4">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    placeholder={t("signUpNameInput")}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                  />
              </div>
            </div>
          
            <div className="w-full">
              <label className="text-sm text-gray-500">ID</label>
              <div className="border border-gray-300 rounded-sm mt-4">
                  <input
                    readOnly = {inputEmail?true:false}
                    type="email"
                    id="email"
                    name="email"
                    maxLength={8}
                    value={email}
                    placeholder={t("Enter the email to be used as the ID")}
                    onChange={emailHandler}
                    className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                  />
              </div>
              {/* 이메일체크 */}
              {/* <div className="mb-1 text-red-400 text-xs px-1">{email.length === 0 || emailChk?'\0':'이메일형식에 맞게 적어주세요'}</div> */}
              <button
                onClick={emailCheck}
                type="button"
                className="w-[50%] text-sm text-white bg-gray-500 rounded-[4px] py-2 my-2"
              >
                {t("E-mail Verify")}
              </button>
            </div>

            <div className="w-full mt-10">
              <label className="text-sm text-gray-500">{t("password")}</label>
              <div className="border border-gray-300 rounded-sm my-4 flex justify-between items-center">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  placeholder={t("Enter Password")}
                  onChange={passwordHandler}
                  className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                />
                {showPassword ? (
                  <button type="button" onClick={() => setShowPassword(false)}>
                    <IoEyeSharp className="mr-4 text-2xl text-gray-500" />
                  </button>
                ) : (
                  <button type="button" onClick={() => setShowPassword(true)}>
                    <BsEyeSlashFill className="mr-4 text-2xl text-gray-500" />
                  </button>
                )}
              </div>
            </div>

            <div className="p-4 border border-gray-300">
              <ul className="list-disc text-gray-500 text-sm pl-3 space-y-2 rounded-sm">
                <li className={`${!lowercaseRegex && "text-red-500"}`}>
                  {t("Password Notice2")}
                </li>
                <li className={`${!numberRegex && "text-red-500"}`}>
                  {t("Password Notice3")}
                </li>
                <li className={`${!atLeast10Regex && "text-red-500"}`}>
                  {t("Password Notice4")}
                </li>
              </ul>
            </div>

            <div className="w-full">
              <div className="border border-gray-300 rounded-sm my-4 flex justify-between items-center">
                <input
                  type={showPasswordConfirm ? "text" : "password"}
                  name="passwordConfirm"
                  value={passwordConfirm}
                  placeholder={t("member_pw2")}
                  onChange={passwordConfirmHandler}
                  className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                />
                {showPasswordConfirm ? (
                  <button
                    type="button"
                    onClick={() => setShowPasswordConfirm(false)}
                  >
                    <IoEyeSharp className="mr-4 text-2xl text-gray-500" />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setShowPasswordConfirm(true)}
                  >
                    <BsEyeSlashFill className="mr-4 text-2xl text-gray-500" />
                  </button>
                )}
              </div>
            </div>
            
            <div className="w-full mb-3">
              <label className="text-sm text-gray-500">{t("signUpPhone")}</label>
              <div className="border border-gray-300 rounded-sm mt-4 mb-4">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={phone}
                    placeholder={t("signUpPhoneInput")}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                  />
              </div>
              
              <label className="text-sm text-gray-500">{t("recommendCode")}</label>
              <div className="flex border border-gray-300 rounded-sm mt-4 mb-4">
                  <input
                    type="text"
                    id="recommendCode"
                    name="recommendCode"
                    value={recommendCode}
                    disabled={isRecommand}
                    placeholder={t("signUpRecommandInput")}
                    onChange={(e) => setRecommendCode(e.target.value)}
                    className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                  />
                  <button onClick={()=>checkRecommand()} className="w-1/3 text-white bg-gray-500 rounded-[4px] py-2 text-sm font-bold">
                  확인
                  </button>
              </div>

              <label className="text-sm text-gray-500">{t("supportCode")}</label>
              <div className="flex border border-gray-300 rounded-sm mt-4 mb-4">
                  <input
                    type="text"
                    id="supportCode"
                    name="supportCode"
                    value={supportCode}
                    disabled={isSupport}
                    placeholder={t("signUpSupportInput")}
                    onChange={(e) => setSupportCode(e.target.value)}
                    className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                  />
                  <button onClick={()=>checkSupport()} className="w-1/3 text-white bg-gray-500 rounded-[4px] py-2 text-sm font-bold">
                  확인
                  </button>
              </div>

              <label className="text-sm text-gray-500">센터/그룹선택</label>
              <div>
                <label className="text-sm text-gray-500 mr-4">
                  <input type="radio" name="center" onChange={()=>setCenterType('1')} value={'1'} checked={centerType === '1'} className="mr-4"/>센터
                </label>
                <label className="text-sm text-gray-500 mr-4">
                  <input type="radio" name="center" onChange={()=>setCenterType('2')} value={'2'} checked={centerType === '2'} className="mr-4"/>그룹
                </label>
              </div>
              {
                centerType === '1' ?
                <>
                <label className="text-sm text-gray-500">센터명</label>
                <div className="border border-gray-300 rounded-sm mt-4 mb-4">
                    <input
                      type="text"
                      id="centerName"
                      name="centerName"
                      value={center.name}
                      placeholder={'센터명을 입력해주세요'}
                      onChange={(e) => 
                          setCenter({
                          ...center,
                          name : e.target.value
                        })
                      }
                      className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                    />
                </div>            

                <label className="text-sm text-gray-500">센터주소</label>
                <div className="border border-gray-300 rounded-sm mt-4 mb-4">
                    <input
                      type="text"
                      id="centerAddress"
                      name="centerAddress"
                      value={center.address}
                      placeholder={'센터주소를 입력해주세요'}
                      onChange={(e) => 
                          setCenter({
                          ...center,
                          address : e.target.value
                        })
                      }
                      className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                    />
                </div>   

                {/* <label className="text-sm text-gray-500">소속그룹</label>
                <div className="border border-gray-300 rounded-sm mt-4 mb-4">
                    <input
                      type="text"
                      id="centerGroup"
                      name="centerGroup"
                      value={center.group}
                      placeholder={'소속그룹을 입력해주세요'}
                      onChange={(e) => 
                          setCenter({
                          ...center,
                          group : e.target.value
                        })
                      }
                      className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                    />
                </div> */}
                </>:
                <>
                <label className="text-sm text-gray-500">그룹명</label>
                <div className="border border-gray-300 rounded-sm mt-4 mb-4">
                    <input
                      type="text"
                      id="centerName"
                      name="centerName"
                      value={group.name}
                      placeholder={'그룹명을 입력해주세요'}
                      onChange={(e) => 
                          setGroup({
                          ...group,
                          name : e.target.value
                        })
                      }
                      className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                    />
                </div>            

                {/* <label className="text-sm text-gray-500">그룹주소</label>
                <div className="border border-gray-300 rounded-sm mt-4 mb-4">
                    <input
                      type="text"
                      id="centerAddress"
                      name="centerAddress"
                      value={group.address}
                      placeholder={'그룹주소를 입력해주세요'}
                      onChange={(e) => 
                          setGroup({
                          ...group,
                          address : e.target.value
                        })
                      }
                      className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                    />
                </div>    */}

                <label className="text-sm text-gray-500">소속그룹</label>
                <div className="border border-gray-300 rounded-sm mt-4 mb-4">
                    <input
                      type="text"
                      id="centerGroup"
                      name="centerGroup"
                      value={group.group}
                      placeholder={'소속그룹을 입력해주세요'}
                      onChange={(e) => 
                          setGroup({
                          ...group,
                          group : e.target.value
                        })
                      }
                      className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                    />
                </div>   
                </>
              }
              

              <label className="text-sm text-gray-500">{t("depositor")}</label>
              <div className="border border-gray-300 rounded-sm mt-4 mb-4">
                  <input
                    type="text"
                    id="depositor"
                    name="depositor"
                    value={depositor}
                    placeholder={t("signUpDepositorInput")}
                    onChange={(e) => setDepositor(e.target.value)}
                    className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                  />
              </div>

              <label className="text-sm text-gray-500">{t("bankName")}</label>
              <div className="border border-gray-300 rounded-sm mt-4 mb-4 relative">
                  <input
                    type="text"
                    id="bankName"
                    name="bankName"
                    value={bankName}
                    placeholder={t("signUpBankNameInput")}
                    onChange={(e) => setBankName(e.target.value)}
                    onKeyUp={(e) => bankNameKeyUp(e.target.value)}
                    className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                  />
                  {/* 입력된 값을 기반으로 리스트를 표시합니다 */}
                  {bankNameSuggestions.length > 0 && (
                    <ul className="absolute left-0 w-full bg-white border border-gray-300 rounded-sm mt-1 z-10 max-h-40 overflow-y-auto">
                      {bankNameSuggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => handleSuggestionClick(suggestion)} // 항목 클릭 시 처리
                          className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                        >
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}
              </div>

              <label className="text-sm text-gray-500">{t("bankAddr")}</label>
              <div className="border border-gray-300 rounded-sm mt-4 mb-4">
                  <input
                    type="text"
                    id="bankAddr"
                    name="bankAddr"
                    value={bankAddr}
                    placeholder={t("signUpBankAddrInput")}
                    onChange={(e) => setBankAddr(e.target.value)}
                    className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                  />
              </div>              
            </div>

            <label className="text-sm text-red-500" style={{ whiteSpace: "pre-line" }}>* 정확한 은행명 선택 및 예금주, 계좌번호를 입력해 주세요. 출금신청시 반려 사유가 될 수 있습니다.{"\n"}</label>
            <label className="text-sm text-gray-500">( 마이페이지에서 수정 가능 )</label>

            {/* OTP */}
            <div className="w-full flex flex-col text-center text-sm font-semibold mb-6">
              <div className="border border-gray-300 rounded-sm mt-4">
                <div>Google Authenticator에서 스캔해주세요</div>
                <div className="flex justify-center">
                  <QRCode
                    size={200}
                    value={`otpauth://totp/LfitWallet?secret=${OTPCode}`}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <div className="flex justify-center mt-4">
                  <p>{OTPCode}</p>
                  <CopyToClipboard text={OTPCode}>
                    <button className="w-1/5 text-13 text-center">{t("copy")}</button>
                  </CopyToClipboard>
                </div>
                <p className="mt-4">Google Authenticator에서 오른쪽 하단 플러스 버튼 &gt;</p>
                <p>설정 키 입력 &gt; 내 키에 상단의 코드를 입력하시면됩니다.</p>
                <p>계정이름은 Wallet이나 자유롭게 작성가능합니다.</p>
              </div>
              <label className="text-sm text-gray-500">OTP인증</label>
              <div className="border border-gray-300 rounded-sm mt-4">
                  <input
                    disabled={OTPChk}
                    type="text"
                    id="optInput"
                    name="optInput"
                    placeholder={'OTP CODE'}
                    onChange={otpHandler}
                    className="w-full focus:outline-none px-3 py-2 text-sm placeholder:text-gray-500"
                  />
              </div>
              {
                OTPChk ? 
                  <button className="w-full text-white bg-gray-500 rounded-[4px] py-2 my-2 text-sm font-bold">
                  인증완료
                </button>:
                  <button onClick={()=>checkOTP()} className="w-full text-white bg-gray-500 rounded-[4px] py-2 my-2 text-sm font-bold">
                  OTP 인증
                </button>
              }
            </div>

            <div className="w-full">
              <button onClick={()=>{onSubmitHandler()}} className="w-full text-white bg-gray-500 rounded-[4px] py-2 my-2 text-sm font-bold">
                {t("Confirm")}
              </button>
            </div>
            
            <div className="w-full flex flex-col text-center text-sm font-semibold mt-6">
              <div className="w-full">
                <span>{t("Already have an account?")}</span>
                <Link to="/login" className="mx-0.5" onClick={()=>{window.scrollTo(0,0)}}>
                  {t("login")}
                </Link>
              </div>
              {/* <div className="w-full mt-2 mb-4">
                <span>{t("Forgot your password?")}</span>
                <Link to="/password_reset" className="mx-0.5" onClick={()=>{window.scrollTo(0,0)}}>
                  {t("Reset your password")}
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default RegisterScreen;
