import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import ko from "./ko.json";
import ja from "./ja.json";
import zh from "./zh.json";
import es from "./es.json";
import pt from "./pt.json";
import ar from "./ar.json";
import vi from "./vi.json";
import th from "./th.json";
import ms from "./ms.json";
import id from "./id.json";
import ru from "./ru.json";
import fr from "./fr.json";
import it from "./it.json";
import de from "./de.json";
import hi from "./hi.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ko: {
      translation: ko,
    },
    ja: {
      translation: ja,
    },
    zh: {
      translation: zh,
    },
    es: {
      translation: es,
    },
    pt: {
      translation: pt,
    },
    ar: {
      translation: ar,
    },
    vi: {
      translation: vi,
    },
    th: {
      translation: th,
    },
    ms: {
      translation: ms,
    },
    id: {
      translation: id,
    },
    ru: {
      translation: ru,
    },
    fr: {
      translation: fr,
    },
    it: {
      translation: it,
    },
    de: {
      translation: de,
    },
    hi: {
      translation: hi,
    },
  },
  lng: "ko",
  fallbackLng: "ko",
  keySeparator: ".",
  debug: process.env.NODE_ENV !== "production",
  interpolation: {
    escapeValue: false,
  },
});
