export const Token = [
  {
    id: 1,
    ticker: "BTC",
    withdrawalMinimum: 0.01,
    withdrawalFee: 0.01,
  },
  {
    id: 2,
    ticker: "ETH",
    withdrawalMinimum: 0.01,
    withdrawalFee: 0.01,
  },
  {
    id: 3,
    ticker: "XRP",
    withdrawalMinimum: 0.01,
    withdrawalFee: 0.01,
  },
  // {
  //   id: 4,
  //   ticker: "USDT",
  //   withdrawalMinimum: 10,
  //   withdrawalFee: 200,
  // },
  {
    id: 5,
    ticker: "LFIT",
    withdrawalMinimum: 10,
    withdrawalFee: 200,
  },
  {
    id: 6,
    ticker: "ENCYKL",
    withdrawalMinimum: 0.0001,
    withdrawalFee: 200,
  },
  {
    id: 7,
    ticker: "VIW",
    withdrawalMinimum: 0.0001,
    withdrawalFee: 200,
  },
  {
    id: 8,
    ticker: "WON",
    withdrawalMinimum: 0.001,
    withdrawalFee: 0.001,
  },
  {
    id: 9,
    ticker: "POINT",
    withdrawalMinimum: 10,
    withdrawalFee: 400,
  },
];
