import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Wrapper from "../components/Wrapper";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaAngleDoubleLeft } from "react-icons/fa";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Preparing from "./preparing";
// import { useTranslation } from "react-i18next";
const ConvertScreen = () => {
  const { t, i18n } = useTranslation();
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
  // const { t } = useTranslation();
  const [nextPage, setNextPage] = useState(false);
  const [mainnet, setMainnet] = useState("eth");
  const [inputs, setInputs] = useState("");
  const [pandoInputs, setPandoInputs] = useState("");
  const [mPandoInputs, setmPandoInputs] = useState("");
  const [pandoVal, setPandoVal] = useState();
  const [mPandoVal, setmPandoVal] = useState();
  const [enablePando, setEnablePando] = useState(true);
  const [enableMPando, setEnableMPando] = useState(true);
  const [afterEth, setAfterEth] = useState();
  const [afterMega, setAfterMega] = useState();
  const [megaKrw, setMegaKrw] = useState(0);
  const [ethKrw, setEthKrw] = useState(0);
  // const [afterEth, setAfterEth] = useState(0);

  const history = useHistory();

  const nextPageHandler = () => {
    setNextPage(!nextPage);
    setMainnet("eth");
  };
  const changeMainnetHandler = (e) => {
    setMainnet(e.target.value);
  };

  // pando -> mpando convert button event
  const convertPando2MPando = () => {
    if (userinfo.EthValue < pandoInputs) {
      alert(t("Swap failed"));
    } else {
      console.log("gfdsgs");
      let body = {
        userID: userinfo.id,
        eth: afterEth,
        mega: afterMega,
        inputEth: pandoVal,
      };
      axios.post("http://3.35.221.77:7272/eth_to_mega", body).then((result) => {
        //console.log(result.data.value);
        if (result.data.value == 1) {
          console.log("result.data.userinfo:", result.data.userinfo);
          history.push("/");
          window.localStorage.setItem(
            "userinfo",
            JSON.stringify(result.data.userinfo)
          );
        } else {
          alert(t("Swap failed"));
          console.log("gfdsgs");
        }
      });
    }
  };

  // useEffect(() => {
  //   console.log("setEnablePando:", setEnablePando);
  // }, [setEnablePando]);

  // pando -> mpando convert input event
  const pandoOnChangeHandler = (e) => {
    let fee = e.target.value * 0.001;
    let value = Number(e.target.value);
    let mPando = Number(userinfo.PandoValue);
    let pando = Number(userinfo.EthValue); // 유저 pando 잔액
    let inputVal = Number(e.target.value) + fee;
    let pandoAfter = Number(pando - inputVal).toFixed(3);
    setPandoVal(Number(e.target.value));
    setmPandoInputs(Number(mPando + value));
    if (pandoAfter < 0) {
      setEnablePando(pandoAfter);
      setPandoInputs("Insufficient Funds");
    } else {
      setPandoInputs(pandoAfter + " " + "ETH");
      // eth + eth * 0.01;
      //원하 * 입력한 갯수
      const krwETH = Number(ethKrw * Math.abs(inputVal));
      // 그거 나누기 스왑하는 토큰의 원화
      const mega = krwETH / megaKrw;
      setAfterEth(Math.abs(pandoAfter));
      setAfterMega(mega.toFixed(3));
      console.log("mega: ", mega);
      console.log("?????", ethKrw * Math.abs(pandoAfter));
      console.log("Number(e.target.value):", Number(e.target.value));
      setmPandoInputs(mega.toFixed(3));
    }
  };

  // mpando -> eth convert input event
  const onChangeHandler = (e) => {
    let fee = e.target.value * 0.001;
    let inputVal = Number(e.target.value) + fee;
    let mPando = Number(userinfo.mPandoValue);
    let mPandoAfter = Number(mPando - inputVal).toFixed(3);
    setmPandoVal(Number(e.target.value));
    if (mPandoAfter < 0) {
      setEnableMPando(false);
      setInputs("Insufficient Funds");
    } else {
      setInputs(mPandoAfter + " " + "MPANDO");
    }
  };

  // mpando -> eth convert button event
  const convertHandler = () => {
    let body = {
      userID: userinfo.id,
      mPandoValue: mPandoVal,
    };
    axios.post("http://3.35.221.77:7272/mPandoEth", body).then((result) => {
      //console.log(result.data.value);
      if (result.data.value === 1) {
        history.push("/");
        //console.log("mPandoEth성공");
        window.localStorage.setItem(
          "userinfo",
          JSON.stringify(result.data.userinfo)
        );
      } else {
        alert(t("Swap failed"));
      }
    });
  };

  // mpando -> btc convert button event
  const convertBtcHandler = () => {
    // alert(t("Under construction"));
    let body = {
      userID: userinfo.id,
      mPandoValue: mPandoVal,
    };
    axios.post("http://3.35.221.77:7272/mPandoBit", body).then((result) => {
      //console.log(result.data.value);
      if (result.data.value == 1) {
        history.push("/");
        //console.log("mPandoBit성공");
        window.localStorage.setItem(
          "userinfo",
          JSON.stringify(result.data.userinfo)
        );
      } else {
        alert(t("Swap failed"));
      }
    });
  };

  useEffect(() => {
    // axios
    //   .post("http://3.35.221.77:7272/get_current_price")
    //   .then((result) => {
    //     // console.log(">>>>>>>>>>>>>>>>>>>>:", result.data.data);
    //     setMegaKrw(result.data.data);
    //   })
    //   .catch((err) => {
    //     console.log("err: ", err);
    //   });
    // axios
    //   .post("http://3.35.221.77:7272/get_current_price_ETH")
    //   .then((result) => {
    //     // console.log(">>>>>>>>>>>>>>>>>>>>:", result.data.data);
    //     setEthKrw(result.data.data);
    //   })
    //   .catch((err) => {
    //     console.log("err: ", err);
    //   });
  }, []);

  // useEffect(() => {
  //   console.log("ethKrw:", ethKrw);
  // }, [ethKrw]);

  // useEffect(() => {
  //   console.log("megaKrw:", megaKrw);
  // }, [megaKrw]);

  return(
    <Preparing />
  )
  // return (
  //   <Wrapper className="min-h-screen flex justify-center items-center px-5">
  //     <div className="hidden xl:flex xl:w-[300px] justify-center items-center">
  //       <img
  //         src="/images/convert/bg-convert.png"
  //         alt="convert"
  //         className="w-[95%]"
  //       />
  //     </div>
  //     <div className="w-[390px] md:w-[660px] xl:w-[1100px] relative bg-gradient-to-r from-[#5c34a2] to-[#7b5ab2] px-5 py-4 md:py-5 xl:py-6 md:px-11 rounded-3xl md:rounded-none md:rounded-bl-xl md:rounded-tr-xl flex flex-col justify-center items-center">
  //       {/* 상단 코인 이미지 */}
  //       <div className="hidden md:flex absolute md:-top-28 xl:-top-40 md:-left-14 xl:-left-20 md:w-28 xl:w-40 md:h-28 xl:h-40 bg-[#5c34a2] rounded-t-xl rounded-bl-xl justify-center items-center">
  //         <img
  //           src="/images/token/eth.png"
  //           alt="pando"
  //           className="md:w-20 md:h-20 xl:w-28 xl:h-28"
  //         />
  //       </div>
  //       <div className="hidden md:block absolute -top-40 md:left-14 xl:left-20 w-40 h-40 bg-[#5c34a2]">
  //         <div className="w-full h-full bg-white rounded-bl-xl"></div>
  //       </div>
  //       {/* 상단 코인 이미지 끝 */}

  //       {nextPage ? (
  //         <div className="w-full">
  //           <div>
  //             <p className="text-white text-2xl md:text-lg xl:text-3xl underline mb-6 text-center space-x-2">
  //               <span>Mainnet to</span>
  //               <select
  //                 defaultValue="eth"
  //                 className="text-black rounded-sm text-xl md:text-base xl:text-2xl"
  //                 onChange={changeMainnetHandler}
  //               >
  //                 <option value="eth">ETH</option>
  //                 <option value="btc">BTC</option>
  //               </select>
  //             </p>
  //           </div>
  //           <div className="flex flex-col md:flex-row justify-center items-center md:space-x-4 xl:space-x-12 mb-4">
  //             <div className="w-full md:w-[25%] flex justify-between md:justify-end items-center mb-10 md:mb-0">
  //               <div className="space-y-1">
  //                 <p className="text-white text-2xl md:text-base xl:text-3xl font-bold text-left md:text-right">
  //                   Mainnet
  //                 </p>
  //                 <div className="flex flex-col items-start md:items-end">
  //                   <span className="text-white text-xs">{t("before")} :</span>
  //                   <span className="text-black font-bold">
  //                     {userinfo.mPandoValue} MPANDO
  //                   </span>
  //                 </div>
  //                 <div className="flex flex-col items-start md:items-end">
  //                   <span className="text-white text-xs">{t("after")} :</span>
  //                   <span className="text-[#cd5c5c] font-bold">
  //                     {/* {inputs} MPANDO */}
  //                     {enableMPando === true ? inputs : inputs}
  //                   </span>
  //                 </div>
  //               </div>
  //               <img
  //                 src="/images/convert/eth.png"
  //                 alt="pando"
  //                 className="w-24 h-24 md:hidden"
  //               />
  //             </div>
  //             <div className="w-full md:w-[50%] mb-10 md:mb-0">
  //               <div className="md:w-[280px] xl:w-full flex flex-col md:flex-row items-center bg-[#fbfbfc33] p-3 rounded-md">
  //                 <input
  //                   type="text"
  //                   name="mpandoAfter"
  //                   className="w-[60%] flex-1 px-2 text-2xl md:text-lg xl:text-2xl bg-transparent text-white font-bold focus:outline-none text-center md:text-start mt-3 md:mt-0"
  //                   onChange={onChangeHandler}
  //                 />
  //                 <span className="text-white text-2xl md:text-sm xl:text-3xl font-bold pb-6 md:pb-0 md:ml-2 xl:ml-4">
  //                   MPANDO
  //                 </span>
  //                 <button
  //                   type="button"
  //                   onClick={() =>
  //                     mainnet === "eth" ? convertHandler() : convertBtcHandler()
  //                   }
  //                   className="bg-[#60db97] p-3 rounded-md text-white font-bold w-full md:w-auto md:ml-2 xl:ml-4"
  //                 >
  //                   {t("Convert")}
  //                 </button>
  //               </div>
  //               <p className="text-center mt-2">
  //                 <span className="text-white">{t("Fees")} : </span>
  //                 <span className="text-[#60db97] font-bold">0.1%</span>
  //               </p>
  //             </div>
  //             <div className="w-full md:w-[25%] md:pb-10 flex justify-between md:justify-start items-center">
  //               {nextPage && mainnet === "btc" && (
  //                 <img
  //                   src="https://pngimg.com/uploads/bitcoin/bitcoin_PNG48.png"
  //                   alt="btc"
  //                   className="w-24 h-24 md:hidden"
  //                 />
  //               )}
  //               {nextPage && mainnet === "eth" && (
  //                 <img
  //                   src="https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png"
  //                   alt="eth"
  //                   className="w-24 h-24 md:hidden rounded-full"
  //                 />
  //               )}
  //               {!nextPage && (
  //                 <img
  //                   src="https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png"
  //                   alt="pando"
  //                   className="w-24 h-24 md:hidden"
  //                 />
  //               )}
  //               <div className="space-y-1">
  //                 <p className="text-white text-2xl md:text-base xl:text-3xl font-bold">
  //                   {mainnet.toUpperCase()}
  //                 </p>
  //                 <div className="flex flex-col items-start">
  //                   <span className="text-white text-xs">{t("before")} :</span>
  //                   <span className="text-black font-bold">
  //                     {mainnet === "eth"
  //                       ? userinfo.EthValue
  //                       : userinfo.BtcValue}
  //                     {mainnet.toUpperCase()}
  //                   </span>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="flex justify-center items-center">
  //             <button type="button" onClick={nextPageHandler}>
  //               <FaAngleDoubleRight className="text-white text-5xl" />
  //             </button>
  //           </div>
  //         </div>
  //       ) : (
  //         <div className="w-full pt-6 md:pt-0">
  //           <div>
  //             <p className="hidden md:block text-white md:text-lg xl:text-3xl underline mb-10 md:mb-5 text-center">
  //               ETH to MEGA
  //             </p>
  //           </div>
  //           <div className="flex flex-col md:flex-row justify-center items-center md:space-x-4 xl:space-x-12 mb-4">
  //             <div className="w-full md:w-[25%] space-y-1 flex justify-between md:justify-end items-center mb-14 md:mb-0">
  //               <img
  //                 src="/images/token/eth.png"
  //                 alt="pando"
  //                 className="w-24 h-24 md:hidden"
  //               />
  //               <div className="space-y-1">
  //                 <p className="text-white text-base xl:text-3xl font-bold text-right">
  //                   ETH
  //                 </p>
  //                 <div className="flex flex-col items-end">
  //                   <span className="text-white text-xs">{t("before")} :</span>
  //                   <span className="text-black font-bold">
  //                     {userinfo.EthValue} ETH
  //                   </span>
  //                 </div>
  //                 <div className="flex flex-col items-end">
  //                   <span className="text-white text-xs">{t("after")} :</span>
  //                   <span className="text-[#cd5c5c] font-bold">
  //                     {enablePando === true ? pandoInputs : pandoInputs}
  //                   </span>
  //                 </div>
  //               </div>
  //               {/* userinfo.EthValue < pandoInputs */}
  //             </div>
  //             <div className="w-full md:w-[50%] mb-[44px] md:mb-0">
  //               <div className="md:w-[280px] xl:w-full flex flex-col md:flex-row items-center bg-[#fbfbfc33] p-3 rounded-md">
  //                 <input
  //                   type="text"
  //                   name="pandoAfter"
  //                   className="w-[60%] flex-1 px-2 text-2xl md:text-lg xl:text-2xl bg-transparent text-white font-bold focus:outline-none text-center md:text-start mt-3 md:mt-0"
  //                   onChange={pandoOnChangeHandler}
  //                 />
  //                 <span className="text-white text-2xl md:text-sm xl:text-3xl font-bold pb-6 md:pb-0 md:ml-2 xl:ml-4">
  //                   ETH
  //                 </span>
  //                 <button
  //                   type="button"
  //                   onClick={convertPando2MPando}
  //                   className="bg-[#60db97] p-3 rounded-md text-white font-bold w-full md:w-auto md:ml-2 xl:ml-4"
  //                 >
  //                   {t("Convert")}
  //                 </button>
  //               </div>
  //               <p className="text-center mt-2">
  //                 <span className="text-white">{t("Fees")} : </span>
  //                 <span className="text-[#60db97] font-bold">0.1%</span>
  //               </p>
  //             </div>
  //             <div className="w-full md:w-[25%] space-y-1 flex justify-between md:justify-start items-center">
  //               <div className="space-y-1">
  //                 <p className="text-white text-base xl:text-3xl font-bold">
  //                   MEGA
  //                 </p>
  //                 <div className="flex flex-col items-start">
  //                   <span className="text-white text-xs">{t("before")} :</span>
  //                   <span className="text-black font-bold">
  //                     {userinfo.PandoValue} MEGA
  //                   </span>
  //                 </div>
  //                 <div className="flex flex-col items-start">
  //                   <span className="text-white text-xs">{t("after")} :</span>
  //                   <span className="text-[#60db97] font-bold">
  //                     {(
  //                       Number(userinfo.PandoValue) + Number(mPandoInputs)
  //                     ).toFixed(3)}{" "}
  //                     MEGA
  //                   </span>
  //                 </div>
  //               </div>
  //               <img
  //                 src="/images/token/mega.png"
  //                 alt="pando"
  //                 className="w-24 h-24 md:hidden"
  //               />
  //             </div>
  //           </div>
  //           <div className="flex justify-center items-center">
  //             {/* <button type="button" onClick={nextPageHandler}> */}
  //             <button type="button">
  //               <FaAngleDoubleRight className="text-white text-5xl" />
  //             </button>
  //           </div>
  //         </div>
  //       )}

  //       {/* 하단 코인 이미지 */}
  //       <div className="hidden md:flex absolute md:-bottom-28 xl:-bottom-40 md:-right-14 xl:-right-20 md:w-28 xl:w-40 md:h-28 xl:h-40 bg-[#7b5ab2] rounded-b-xl rounded-tr-xl justify-center items-center">
  //         {nextPage && mainnet === "btc" && (
  //           <img
  //             src="https://pngimg.com/uploads/bitcoin/bitcoin_PNG48.png"
  //             alt="btc"
  //             className="md:w-28 md:h-28 xl:w-36 xl:h-36"
  //           />
  //         )}
  //         {nextPage && mainnet === "eth" && (
  //           <img
  //             src="https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png"
  //             alt="eth"
  //             className="md:w-20 md:h-20 xl:w-28 xl:h-28 rounded-full"
  //           />
  //         )}
  //         {!nextPage && (
  //           <img
  //             src="/images/token/mega.png"
  //             alt="pando"
  //             className="md:w-20 md:h-20 xl:w-28 xl:h-28"
  //           />
  //         )}
  //       </div>
  //       <div className="hidden md:block absolute -bottom-40 md:right-14 xl:right-20 w-40 h-40 bg-[#7b5ab2]">
  //         <div className="w-full h-full bg-white rounded-tr-xl"></div>
  //       </div>
  //       {/* 하단 코인 이미지 끝 */}
  //     </div>
  //     <div className="hidden xl:flex xl:w-[300px] h-20 justify-center items-center">
  //       <img
  //         src="/images/convert/bg-convert.png"
  //         alt="convert"
  //         className="w-[95%]"
  //       />
  //     </div>
  //   </Wrapper>
  // );
};

export default ConvertScreen;
