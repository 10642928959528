import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import jsSHA from "jssha";
import { API_URL2, getUser } from "../../utils/api";

const CoinBuyPopup = ({
  popup,
  setPopup,
  status,
  balance,
  setRefresh,
  type = 0
}) => {
  const { t, i18n } = useTranslation();
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));

  const [otpCode, setOtpCode] = useState();
  const [OTP, setOTP] = useState('');
  const [OTPInput, setOTPInput] = useState('');
  const [OTPChk, setOTPChk] = useState(false);
  const intervalRef = useRef(null);
  
  const otpCodeHandler = (e) => {
    setOTPInput(e.target.value);
  };

  const history = useHistory();

  const [OTPCode, setOTPCode] = useState('');
  
  const walletList = [
    {
        id: 1,
        end: "buyBTC",
        title : "BTC"
    },
    {
        id: 2,
        end: "buyXRP",
        title : "XRP"
    },
    {
        id: 3,
        end: "buyLfit",
        title : "LFIT"
    },
    {
        id: 4,
        end: "buyEncykl",
        title : "Encykl"
    },
    {
        id: 5,
        end: "buyViw",
        title : "VIW"
    },
    ];

  const otpComf = () => {
    $.ajax({ 
      url: `${API_URL2}/otpCheck`, 
      type: "POST", 
      data: {'userPK': userinfo.id, 'otpNum' : OTPInput}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        if(result.code === '1'){
          setOTPChk(true)
          buyCoin();
        }
        else{
          alert("OTP가 일치하지 않습니다.");
        }
      }, error: function (error) { 
        console.log(error) 
      } 
    })
  };

  const buyCoin = () => {
    $.ajax({ 
      url: `${API_URL2}/${walletList[type].end}`, 
      type: "POST", 
      data: { 'userPK': userinfo.id, "status" : status, "balance" : balance}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        console.log(result) 
        if(result.code === '1'){
          updateUser();
        }
        else if(result.code === '5'){
          alert('보유 금액이 부족합니다.')
          setPopup(0)
        }
      }, error: function (error) { 
        console.log(error) 
      } 
    })
  }

  const updateUser = () => {
    const datachk = localStorage.getItem("userinfo");
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    const data = {
      userPK : userinfo.id,
    }
    getUser(data).then(res => {
      localStorage.setItem("userinfo", JSON.stringify(res.data.user));
      initToken();
      setPopup(0)
    })
  }
  
  const initToken = () => {
    $.ajax({ 
      url: `${API_URL2}/myWalletAllBalance`, 
      type: "POST", 
      data: { 'userPK': userinfo.id}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        console.log(result)
        localStorage.setItem('btcVal', result.btcVal)
        localStorage.setItem('xrpVal', result.xrpVal)
        localStorage.setItem('lfitVal', result.lfitVal)
        localStorage.setItem('ethVal', result.ethVal)
        localStorage.setItem('krwVal', result.krwVal)
        localStorage.setItem('point', result.point)
        localStorage.setItem('encykl', result.encykl)
        localStorage.setItem('usdtVal', result.usdtVal)
        localStorage.setItem('viwVal', result.viwVal)
        alert(`${walletList[type].title} 구매 완료`)
        setRefresh(true)
      }, error: function (error) { 
        console.log(error)
      } 
    })
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[9999]">
      <div
        className="absolute top-0 left-0 w-full h-full bg-[#00000050]"
        onClick={() => setPopup(0)}
      ></div>
      <div className="relative w-[400px] bg-white rounded">
        <div className="flex justify-between border-b border-solid border-black/0.125 p-4">
          <div className="font-bold">
            {walletList[type].title} 구매
          </div>
          <button className="w-3" onClick={() => setPopup(0)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill="#6c757d"
            >
              <path d="M376.6 427.5c11.31 13.58 9.484 33.75-4.094 45.06c-5.984 4.984-13.25 7.422-20.47 7.422c-9.172 0-18.27-3.922-24.59-11.52L192 305.1l-135.4 162.5c-6.328 7.594-15.42 11.52-24.59 11.52c-7.219 0-14.48-2.438-20.47-7.422c-13.58-11.31-15.41-31.48-4.094-45.06l142.9-171.5L7.422 84.5C-3.891 70.92-2.063 50.75 11.52 39.44c13.56-11.34 33.73-9.516 45.06 4.094L192 206l135.4-162.5c11.3-13.58 31.48-15.42 45.06-4.094c13.58 11.31 15.41 31.48 4.094 45.06l-142.9 171.5L376.6 427.5z" />
            </svg>
          </button>
        </div>
        {
          <>
            <form className="p-4">
              <div className="border-b border-solid border-black/0.125">
                <div className="flex justify-between items-center text-13 mb-3">
                  <label htmlFor="withdrawalAddress">
                    {
                      status === 1 ? '원화금액' : 
                      status === 2 ? '포인트구매' : 
                      'Tether 갯수'
                    }
                  </label>
                  <div className="w-[200px] flex justify-end items-center border border-solid border-black/0.125 p-1">
                    <input
                      id="withdrawalAddress"
                      // type="text"
                      disabled="disabled"
                      className="bg-white font-13 text-end focus:outline-none"
                      value={String(balance)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center text-13 my-4">
                <label htmlFor="OTP">OTP input</label>
                <div className="w-[200px] flex justify-end items-center border border-solid border-black/0.125 p-1">
                  <input
                    id="OTP"
                    type="text"
                    className="bg-white font-13 text-end focus:outline-none mr-1"
                    onChange={otpCodeHandler}
                  />
                  <div> OTP</div>
                </div>
              </div>
            </form>
            <div className="flex justify-between items-center p-4 pt-0">
              <button
                onClick={() => setPopup(0)}
                className="w-[48%] text-13 text-black border border-solid border-black/0.125 rounded-sm py-2"
              >
                Cancel
              </button>
              <button
                className="w-[48%] text-13 text-white bg-black rounded-sm py-2"
                onClick={otpComf}
              >
                Confirm
              </button>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default CoinBuyPopup;
