import React, { useEffect, useState, useRef } from "react";
import { Token } from "../../data/tokenList";
import WalletTokenList from "./WalletTokenList";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CoinBuyPopup from "./CoinBuyPopup";

const WalletENCYKLBuy = ({ }) => {

  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));

  const { t } = useTranslation();
  const [popup, setPopup] = useState(0);
  const [status, setStatus] = useState(1);
  const [balance, setBalance] = useState(0);
  const [clear, setClear] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const [info, setInfo] = useState({
    krw : Number(userinfo.krw),
    point : Number(userinfo.point),
  })

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

useEffect(()=>{
  if(refresh){
    setRefresh(false)
    if (inputRef1.current) {
      inputRef1.current.value = '';
    }
    if (inputRef2.current) {
      inputRef2.current.value = '';
    }

    setBalance(0)
    setStatus(1)
    setInfo({
      krw : Number(JSON.parse(window.localStorage.getItem("userinfo")).krw),
      point : Number(JSON.parse(window.localStorage.getItem("userinfo")).point)
    })
  }
}, [refresh])

  useEffect(() => {
    balance > 0 ? setClear(true) : setClear(false)
  }, [balance]);

  const handleRadio = (e) => {
    setStatus(Number(e.target.value))
  }

  return (
    <>
      <div>
        <div className="text-13 m-2 pt-2 mt-3">
        <p className="text-center mb-3">ENCYKL 구매</p>
        {/* <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-6">
          <li className="w-full flex justify-center border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div className="flex items-center ps-3">
                  <input id="krwInput" type="radio" value={'1'} name="list-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" checked={status === 1} onChange={handleRadio}/>
                  <label htmlFor="krwInput" className="w-full py-3 m-2 text-sm font-medium text-gray-900 dark:text-gray-300">원화 구매</label>
              </div>
          </li>
          <li className="w-full flex justify-center border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div className="flex items-center ps-3">
                  <input id="pointInput" type="radio" value={'2'} name="list-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" checked={status === 2} onChange={handleRadio}/>
                  <label htmlFor="pointInput" className="w-full py-3 m-2 text-sm font-medium text-gray-900 dark:text-gray-300">포인트</label>
              </div>
          </li>
        </ul> */}
        {
          status === 1 ?
          <>
            <h6 className="pb-2">원화 구매 <span className="text-13 text-[#6c757d]">(보유 : {info.krw.toLocaleString()}원)</span></h6>
            <input
              ref={inputRef1}
              type="number"
              min={0}
              onChange={(e) => {setBalance(e.target.value)}}
              placeholder="Amount"
              className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            />
            {/* <p>최소 출금금액은 50,000원 이상입니다.</p> */}
          </> :
          <>
            <h6 className="pb-2">포인트 <span className="text-13 text-[#6c757d]">(보유 : {info.point.toLocaleString()}원)</span></h6>
            <input
              ref={inputRef2}
              type="number"
              min={0}
              onChange={(e) => {setBalance(e.target.value)}}
              placeholder="Amount"
              className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            />
            {/* <p>최소 출금금액은 50,000원 이상입니다.</p> */}
          </>
        }
        </div>

        <button
          className={`mt-3 w-full bg-[#1c2335] text-13 text-white rounded-sm ${clear ? 'opacity-100' : 'opacity-60' } py-1.5`}
          onClick={() => {setPopup(1)}} // onSubmitData()
          disabled={!clear}
        >
          {t("purchase")}
        </button>

        {popup ? (
        <CoinBuyPopup
          popup={popup}
          setPopup={setPopup}
          status={status}
          balance={balance}
          setRefresh={setRefresh}
          type={3}
        />
      ) : null}
      </div>
    </>
  );
};

export default WalletENCYKLBuy;
