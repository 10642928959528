export default () => {
  if (localStorage.getItem("pandoswap_theme")) {
    if (localStorage.getItem("pandoswap_theme") === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  } else {
    localStorage.setItem("pandoswap_theme", "light");
    document.documentElement.classList.remove("dark");
  }
};
