import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userLogoutAction } from "../redux/actions/userAction";
import { BsFillMoonFill, BsFillSunFill } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Sidebar = ({ toggleSidebar, showSidebar }) => {
  const { t } = useTranslation();
  const [dropdown, setDropdown] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("pandoswap_theme"));
  // let userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
  const history = useHistory();
  const [userinfo, setUserinfo] = useState({...JSON.parse(localStorage.getItem('userinfo'))});
  //console.log("Sidebar 컴포넌트에서 userinfo", userinfo);

  //isAuthenticated
  let isAuthenticated = useSelector((state) => {
    return state.user.isAuthenticated;
  });

  let dispatch = useDispatch();

  useEffect(()=>{
    getUserinfo();
  },[])

  const handleLogout = () => {
    toggleSidebar();
    //log out function
    //console.log("log out");
    dispatch(userLogoutAction());
    window.localStorage.removeItem("userinfo");
    history.push('/');
  };


  const getUserinfo = async () => {
    setUserinfo({
      ...JSON.parse(await localStorage.getItem('userinfo'))
    })
  }

  const darkThemeHandler = () => {
    localStorage.setItem("pandoswap_theme", "dark");
    document.documentElement.classList.add("dark");
    setTheme("dark");
  };

  const lightThemeHandler = () => {
    localStorage.setItem("pandoswap_theme", "light");
    document.documentElement.classList.remove("dark");
    setTheme("light");
  };

  return (
    <>
      <div
        className={`absolute top-0 left-0 min-h-screen bg-gray-900/50 z-40 ${
          showSidebar ? "w-screen" : "w-0"
        }`}
        onClick={toggleSidebar}
      />
      <aside
        className={`bg-zinc-50 absolute right-0 top-0 min-h-screen z-50 transition-width duration-300 overflow-hidden dark:bg-slate-800 ${
          showSidebar ? "w-64" : "w-0"
        }`}
      >
        <Link to={"/"} className="flex justify-center py-9 w-64">
          <img
            className="w-12"
            src="/images/header/icon2.svg"
            alt="logo"
            onClick={() => toggleSidebar()}
          />
        </Link>
        
          <div className="flex w-64 justify-center py-6 mb-7 ">
            <ul className="w-full px-4 dark:text-white">
              <li
                className="w-full mb-5 text-sm relative"
                onClick={toggleSidebar}
              >
                <Link to="/wallet" className="flex items-center w-full">
                  <img
                    className="w-4 h-4 dark:contrast-0"
                    src="/images/common/wallet-solid.svg"
                    alt=""
                  />
                  <span className="ml-4">{t("Wallet")}</span>
                  <img
                    className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                    src="/images/common/right_arrow.svg"
                    alt=""
                  />
                </Link>
              </li>

              <li
                className=" w-full mb-5 text-sm relative"
                onClick={toggleSidebar}
              >
                <Link to="/convert" className="flex items-center w-full">
                  <img
                    className="w-4 h-4 dark:contrast-0"
                    src="/images/common/right-left-solid.svg"
                    alt=""
                  />
                  <span className="ml-4">{t("Convert")}</span>
                  <img
                    className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                    src="/images/common/right_arrow.svg"
                    alt=""
                  />
                </Link>
              </li>

              <li
                className=" w-full mb-5 text-sm relative"
                onClick={toggleSidebar}
              >
                <Link to="/krw" className="flex items-center w-full">
                  <img
                    className="w-4 h-4 dark:contrast-0"
                    src="/images/common/right-left-solid.svg"
                    alt=""
                  />
                  <span className="ml-4">KRW</span>
                  <img
                    className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                    src="/images/common/right_arrow.svg"
                    alt=""
                  />
                </Link>
              </li>

              {/* <li
                // onClick={() => setDropdown(!dropdown)}
                onClick={() => toggleSidebar()}
                className="flex items-center w-full mb-5 text-sm relative cursor-pointer"
              >
                <img
                  className="w-4 h-4 dark:contrast-0"
                  src="/images/common/user-solid.svg"
                  alt=""
                />
                <span className="ml-4">
                  <Link
                    to={{
                      pathname: "/mypage",
                      state: { tabName: "MY_PROFILE" },
                    }}
                    className="flex items-center "
                  >
                    <span>{t("Profile")}</span>
                  </Link>
                </span>
                <img
                  className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                  src="/images/common/right_arrow.svg"
                  alt=""
                />
                {dropdown && (
                  <div className="border border-gray-300 text-sm absolute top-6 left-2 z-50">
                    <div className="py-3 px-4 border-b border-b-gray-300 bg-gray-50 text-gray-400">
                      {userinfo.username}
                    </div>
                    <ul className="px-4 bg-white">
                      <li
                        className="flex items-center py-3"
                        onClick={toggleSidebar}
                      >
                        <Link
                          to={{
                            pathname: "/mypage",
                            state: { tabName: "MY_PROFILE" },
                          }}
                          className="flex items-center "
                        >
                          <img
                            className="w-3.5 h-3.5 mr-1"
                            src="/images/common/user-solid.svg"
                            alt=""
                          />
                          <span>{t("Profile")}</span>
                        </Link>
                      </li>
                      <li
                        className="flex items-center py-3"
                        onClick={toggleSidebar}
                      >
                        <Link
                          to={{
                            pathname: "/mypage",
                            state: { tabName: "OTP_SETTINGS" },
                          }}
                          className="flex items-center"
                        >
                          <img
                            className="w-3.5 h-3.5 mr-1"
                            src="/images/common/fingerprint-solid.svg"
                            alt=""
                          />
                          <span>{t("OTP Setting")}</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </li> */}

              {
              isAuthenticated && 
                <li className="w-full flex mb-5 text-sm relative" style={{cursor:"pointer"}} onClick={()=>{
                  toggleSidebar();
                }}>
                  <img
                    className="w-4 h-4 dark:contrast-0"
                    src="/images/common/user-solid.svg"
                    alt=""
                  />
                  <span className="ml-4">
                    <Link
                      to={{
                        pathname: "/mypage",
                        state: { tabName: "MY_PROFILE" },
                      }}
                      className="flex items-center "
                    >
                      <span>{t("Profile")}</span>
                    </Link>
                  </span>
                  <img
                    className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                    src="/images/common/right_arrow.svg"
                    alt=""
                    />
                </li>
              }
{/* 
              {
              isAuthenticated && 
                <CopyToClipboard text={userinfo.supportCode}>
                <li className="w-full flex mb-5 text-sm relative" style={{cursor:"pointer"}} onClick={()=>{
                  alert(`${userinfo.supportCode} 복사완료`)
                  toggleSidebar();
                }}>
                  <img
                    className="w-4 h-4 dark:contrast-0"
                    src="/images/common/copy-solid.svg"
                    alt=""
                  />
                  <div>
                    <span className="ml-4">후원코드</span>
                    <span className="ml-2">{userinfo.supportCode}</span>
                  </div>
                  <img
                    className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                    src="/images/common/right_arrow.svg"
                    alt=""
                    />
                </li>
                </CopyToClipboard>
              }

              {
              isAuthenticated && 
                <CopyToClipboard text={userinfo.recommendCode}>
                <li className="w-full flex mb-5 text-sm relative" style={{cursor:"pointer"}} onClick={()=>{
                  alert(`${userinfo.recommendCode} 복사완료`)
                  toggleSidebar();
                }}>
                  <img
                    className="w-4 h-4 dark:contrast-0"
                    src="/images/common/copy-solid.svg"
                    alt=""
                  />
                  <div>
                    <span className="ml-4">추천코드</span>
                    <span className="ml-2">{userinfo.recommendCode}</span>
                  </div>
                  <img
                    className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                    src="/images/common/right_arrow.svg"
                    alt=""
                    />
                </li>
                </CopyToClipboard>
              } */}

            {
            isAuthenticated && 
            <>
              <li className="w-full flex mb-5 text-sm relative" style={{cursor:"pointer"}}>
                <img
                  className="w-4 h-4 dark:contrast-0"
                  src="/images/common/user-solid.svg"
                  alt=""
                />
                <span className="ml-4" onClick={()=>{toggleSidebar();}}>
                  <Link
                    to={{
                      pathname: "/mypage",
                      state: { tabName: "ORGANIZATION" },
                    }}
                    className="flex items-center "
                  >
                    <span>추천계보도</span>
                  </Link>
                </span>
                <img
                  className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                  src="/images/common/right_arrow.svg"
                  alt=""
                  />
              </li>
              <li className="w-full flex mb-5 text-sm relative" style={{cursor:"pointer"}}>
                <img
                  className="w-4 h-4 dark:contrast-0"
                  src="/images/common/user-solid.svg"
                  alt=""
                />
                <span className="ml-4" onClick={()=>{toggleSidebar();}}>
                  <Link
                    to={{
                      pathname: "/mypage",
                      state: { tabName: "SPONSE" },
                    }}
                    className="flex items-center "
                  >
                    <span>후원계보도</span>
                  </Link>
                </span>
                <img
                  className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                  src="/images/common/right_arrow.svg"
                  alt=""
                  />
              </li>
              <li className="w-full flex mb-5 text-sm relative" style={{cursor:"pointer"}}>
                <img
                  className="w-4 h-4 dark:contrast-0"
                  src="/images/common/user-solid.svg"
                  alt=""
                />
                <span className="ml-4" onClick={()=>{toggleSidebar();}}>
                  <Link
                    to={{
                      pathname: "/mypage",
                      state: { tabName: "INQUERY" },
                    }}
                    className="flex items-center "
                  >
                    <span>1:1문의</span>
                  </Link>
                </span>
                <img
                  className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                  src="/images/common/right_arrow.svg"
                  alt=""
                  />
              </li>
            </>
            }

              {isAuthenticated && <li
                onClick={handleLogout}
                className="flex items-center w-full text-sm relative cursor-pointer"
              >
                <img
                  className="w-4 h-4 dark:contrast-0"
                  src="/images/common/right-from-bracket-solid.svg"
                  alt=""
                />
                <span className="ml-4">{t("Logout")}</span>
                <img
                  className="w-3.5 h-3.5 absolute right-0 dark:contrast-0"
                  src="/images/common/right_arrow.svg"
                  alt=""
                />
              </li>}
            </ul>
          </div>
          {isAuthenticated ? 
          '' : (
          <div className="flex w-64 justify-center py-6 mb-7 ">
            <Link to={"/signup"}>
              <button
                onClick={() => toggleSidebar(false)}
                className="rounded mx-2 border-solid border-gray-500 h-8 w-16 text-xs text-gray-800 cursor-pointer dark:text-white"
                style={{ borderWidth: "1px" }}
              >
                {t("Join")}
              </button>
            </Link>
            <Link to={"/login"}>
              <button
                onClick={() => toggleSidebar(false)}
                className="rounded mx-2 border-solid border-gray-500 h-8 w-16 text-xs text-gray-800 cursor-pointer dark:text-white"
                style={{ borderWidth: "1px" }}
              >
                {t("login")}
              </button>
            </Link>
          </div>
        )}
        <div className="flex justify-center items-center mb-5">
          {theme === "dark" ? (
            <button type="button" onClick={lightThemeHandler}>
              <BsFillMoonFill className="text-xl text-gray-200" />
            </button>
          ) : (
            <button type="button" onClick={darkThemeHandler}>
              <BsFillSunFill className="text-xl text-orange-300" />
            </button>
          )}
        </div>
        {/* <div className="text-center text-10 text-gray-500 w-64">
          info@megaprotocol.io
        </div> */}
      </aside>
    </>
  );
};

export default Sidebar;
