import React, { useEffect, useState } from "react";
import axios from "axios";
import { use } from "i18next";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Reset = () => {
  const { t, i18n } = useTranslation();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const checkUpperCase = /^(?=.*?[A-Z]).{1,}$/.test(password);
  const checkLowerCase = /^(?=.*?[a-z]).{1,}$/.test(password);
  const checkNumber = /^(?=.*?[0-9]).{1,}$/.test(password);
  const checkPasswordLength = password.length >= 10;
  const [isDisabled, setIsDisabled] = useState(true);
  const [inputRead, setInputRead] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [code, setCode] = useState();
  const [codeValue, setCodeValue] = useState();
  const [resetStatus, setResetStatus] = useState();
  const [codeStatus, setCodeStatus] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const disabledState =
      password === passwordConfirm &&
      checkUpperCase &&
      checkLowerCase &&
      checkNumber &&
      checkPasswordLength;
    setIsDisabled(!disabledState);
  }, [password, passwordConfirm]);

  const onChangeHandler = (e) => {
    setUserEmail(e.target.value);
  };
  const codeValueHandler = (e) => {
    setCodeValue(e.target.value);
  };

  const onClickReset = (e) => {
    alert(t("Verification code sent"));
    setCodeStatus(true);
    // password reset
    e.preventDefault();
    const body = {
      userEmail: userEmail,
    };
    axios
      .post("http://3.35.221.77:7272/signup_emailCer", body)
      .then((result) => {
        //console.log("result.data.value:", result.data.value);
        if (result.data.value === "1") {
          setCode(result.data.code);
          // //console.log(result.data.code); //지워야됨
        } else if (result.data.value === "0") {
          setCodeStatus(false);
          alert(t("Verification code could not be sent. Please try again"));
        } else if (result.data.value === "2") {
          setCodeStatus(false);
          alert(t("User does not exist"));
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const codeCheck = (e) => {
    e.preventDefault();
    codeValue === code ? check() : alert(t("Verification failed"));
  };

  const check = () => {
    alert(t("Verified"));
    setInputRead(true);
    setResetStatus(true);
  };

  const pwReset = (e) => {
    e.preventDefault();
    const body = {
      userEmail: userEmail,
      newPw: passwordConfirm,
    };
    axios
      .post("http://3.35.221.77:7272/reactChangeUserPw", body)
      .then((result) => {
        if (result.data.value === "1") {
          alert(t("Password changed"));
          history.push("/login");
        } else if (result.data.value === "0") {
          alert(t("Password could not be changed"));
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  return (
    <section className="flex justify-center p-4 my-12">
      <div className="w-full max-w-[720px]">
        <div className="text-[14px] font-bold text-center mt-4 mb-6">
          Set New Password
        </div>
        {!resetStatus ? (
          <>
            {!codeStatus ? (
              <form className="w-full">
                <div className="w-full mb-8">
                  {inputRead ? (
                    <input
                      readOnly
                      type="email"
                      placeholder="E-mail"
                      className="border border-gray py-1.5 px-3 rounded focus:outline-none w-full text-sm"
                      value={userEmail}
                    />
                  ) : (
                    <input
                      type="email"
                      placeholder="E-mail"
                      className="border border-gray py-1.5 px-3 rounded focus:outline-none w-full text-sm"
                      value={userEmail}
                      onChange={onChangeHandler}
                    />
                  )}
                </div>
                <div className="w-full">
                  <button
                    onClick={onClickReset}
                    className="w-full text-white bg-gray-500 font-semibold text-sm py-1.5 px-3 rounded"
                  >
                    {t("Send Verification Code")}
                  </button>
                </div>
              </form>
            ) : null}

            {codeStatus ? (
              <form className="w-full mt-8">
                <div className="w-full mb-8">
                  {inputRead ? (
                    <input
                      readOnly
                      type="email"
                      placeholder="Authentication code"
                      className="border border-gray py-1.5 px-3 rounded focus:outline-none w-full text-sm"
                      vaule={codeValue}
                    />
                  ) : (
                    <input
                      type="email"
                      placeholder="Authentication code"
                      className="border border-gray py-1.5 px-3 rounded focus:outline-none w-full text-sm"
                      vaule={codeValue}
                      onChange={codeValueHandler}
                    />
                  )}
                </div>
                <div className="w-full">
                  <button
                    onClick={codeCheck}
                    className="w-full text-white bg-gray-500 font-semibold text-sm py-1.5 px-3 rounded"
                  >
                    {t("Verify")}
                  </button>
                </div>
              </form>
            ) : null}
          </>
        ) : null}

        {!resetStatus ? null : (
          <form action="" className="text-[14px]">
            <p className="text-[#6c757d] mt-6">New Password</p>
            <div className="relative flex items-center mt-4">
              <input
                type={isShowPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                className="w-full text-[14px] leading-normal border border-solid border-[#ced4da] rounded py-2 px-3 focus:outline-none"
              />
              <div className="absolute right-0 mr-4 cursor-pointer">
                {isShowPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#6c757d"
                    viewBox="0 0 16 16"
                    onClick={() => setIsShowPassword(false)}
                  >
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#6c757d"
                    viewBox="0 0 16 16"
                    onClick={() => setIsShowPassword(true)}
                  >
                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                  </svg>
                )}
              </div>
            </div>
            <ul className="text-[#6c757d] border border-solid border-[#00000125] bg-[#F8F9FA] rounded px-6 py-4 mt-4">
              <li className="list-disc my-1">
                <span
                  className={
                    checkUpperCase ? "text-[#6c757d]" : "text-[#ff4c5e]"
                  }
                >
                  An uppercase
                </span>
              </li>
              <li className="list-disc my-1">
                <span
                  className={
                    checkLowerCase ? "text-[#6c757d]" : "text-[#ff4c5e]"
                  }
                >
                  A lowercase
                </span>
              </li>
              <li className="list-disc my-1">
                <span
                  className={checkNumber ? "text-[#6c757d]" : "text-[#ff4c5e]"}
                >
                  A number
                </span>
              </li>
              <li className="list-disc my-1">
                <span
                  className={
                    checkPasswordLength ? "text-[#6c757d]" : "text-[#ff4c5e]"
                  }
                >
                  At least 10 characters
                </span>
              </li>
            </ul>
            <div className="relative flex items-center mt-4">
              <input
                type={isShowPassword ? "text" : "password"}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                placeholder="Confirm Password"
                className="w-full text-[14px] leading-normal border border-solid border-[#ced4da] rounded py-2 px-3 focus:outline-none"
              />
              <div className="absolute right-0 mr-4 cursor-pointer">
                {isShowPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#6c757d"
                    viewBox="0 0 16 16"
                    onClick={() => setIsShowPassword(false)}
                  >
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#6c757d"
                    viewBox="0 0 16 16"
                    onClick={() => setIsShowPassword(true)}
                  >
                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                  </svg>
                )}
              </div>
            </div>
            <button
              disabled={isDisabled}
              className={`${
                isDisabled ? "opacity-60" : "opacity-100"
              } w-full text-[#ffffff] font-bold bg-[#1c2335] border-none rounded p-2 mt-6`}
              onClick={pwReset}
            >
              Confirm
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default Reset;
