export const uppercase = (phrase) => {
  const regex = new RegExp(/[A-Z]+/);
  return regex.test(phrase);
};

export const lowercase = (phrase) => {
  const regex = new RegExp(/[a-z]+/);
  return regex.test(phrase);
};

export const number = (phrase) => {
  const regex = new RegExp(/[0-9]+/);
  return regex.test(phrase);
};
