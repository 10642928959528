import React from "react";
import { Route, Redirect } from "react-router-dom";
import LoginScreen from "../screens/LoginScreen";
import { useSelector, useDispatch } from "react-redux";

const PrivateComponent = ({ component: Component, ...rest }) => {
  // 질문1. isAuthenticated 를 참조하는 곳이 없다. 그래서 이 컴포넌트 안에서만 인식되는 것인지..
  // 질문2. const 로 선언되어있는 것이 잘못된건 아닌지
  let isAuthenticated = useSelector((state) => {
    return state.user.isAuthenticated;
  });

  // const isAuthenticated = state.user.isAuthenticated;
  // //console.log(isAuthenticated);
  // //console.log(state.user.isAuthenticated);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <LoginScreen {...props} />
      }
    />
  );
};

export default PrivateComponent;
