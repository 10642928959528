import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import jsSHA from "jssha";
import { API_URL2, getUser } from "../utils/api";

const InquirySubmitPopup = ({
  popup,
  setPopup,
  setIsRefresh
}) => {
  const { t, i18n } = useTranslation();
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [once, setOnce] = useState(true);
  const inquirySubmit = () => {
    if(!title){
      alert('제목을 입력해주세요')
    }
    else if(!contents){
      alert('내용을 입력해주세요')
    }
    else if(title && contents){
      if(once){
        setOnce(false)
        $.ajax({ 
          url: `${API_URL2}/inquirySubmit`, 
          type: "POST", 
          data: { 'userPK': userinfo.id, "title" : title, "contents" : contents}, 
          dataType: "json", 
          async: true, 
          success: function (result) { 
            console.log(result) 
            if(result.code === '1'){
              setIsRefresh(true)
              setPopup(0)
            }
            
          }, error: function (error) { 
            console.log(error) 
          } 
        }).then(()=>{
          setOnce(true)
        })
      }
    }
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[9999]">
      <div
        className="absolute top-0 left-0 w-full h-full bg-[#00000050]"
        onClick={() => setPopup(0)}
      ></div>
      <div className="relative w-[400px] bg-white rounded">
        <div className="flex justify-between border-b border-solid border-black/0.125 p-4">
          <div className="font-bold">
            1:1문의
          </div>
          <button className="w-3" onClick={() => setPopup(0)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill="#6c757d"
            >
              <path d="M376.6 427.5c11.31 13.58 9.484 33.75-4.094 45.06c-5.984 4.984-13.25 7.422-20.47 7.422c-9.172 0-18.27-3.922-24.59-11.52L192 305.1l-135.4 162.5c-6.328 7.594-15.42 11.52-24.59 11.52c-7.219 0-14.48-2.438-20.47-7.422c-13.58-11.31-15.41-31.48-4.094-45.06l142.9-171.5L7.422 84.5C-3.891 70.92-2.063 50.75 11.52 39.44c13.56-11.34 33.73-9.516 45.06 4.094L192 206l135.4-162.5c11.3-13.58 31.48-15.42 45.06-4.094c13.58 11.31 15.41 31.48 4.094 45.06l-142.9 171.5L376.6 427.5z" />
            </svg>
          </button>
        </div>
        {
          <>
            <form className="p-4">
              <div className="text-13 my-4">
                <label className="my-4" htmlFor="title">문의제목</label>
                <div className="w-full flex justify-end items-center border border-solid border-black/0.125 p-1">
                  <input
                    id="title"
                    type="text"
                    placeholder="제목"
                    className="w-full bg-white font-13 text-start focus:outline-none mr-1"
                    onChange={(e)=> {setTitle(e.target.value)}}
                  />
                </div>
              </div>

              <div className="text-13 my-4">
                <label className="my-4" htmlFor="contents">문의내용</label>
                <div className="w-full flex justify-end items-center border border-solid border-black/0.125 p-1">
                  <textarea
                    id="contents"
                    type="text"
                    placeholder="내용"
                    className="w-full bg-white font-13 text-start focus:outline-none mr-1"
                    style={{minHeight : '4rem'}}
                    onChange={(e)=> {setContents(e.target.value)}}
                  />
                  
                </div>
              </div>
            </form>
            <div className="flex justify-between items-center p-4 pt-0">
              <button
                onClick={() => setPopup(0)}
                className="w-[48%] text-13 text-black border border-solid border-black/0.125 rounded-sm py-2"
              >
                Cancel
              </button>
              <button
                className="w-[48%] text-13 text-white bg-black rounded-sm py-2"
                onClick={inquirySubmit}
              >
                Confirm
              </button>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default InquirySubmitPopup;
