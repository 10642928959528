import React from "react";
import { Link, withRouter } from "react-router-dom";
import { IoCaretUpCircle, IoHome } from "react-icons/io5";
import { FaWallet, FaExchangeAlt, FaUserAlt } from "react-icons/fa";

const Footer = ({ location }) => {
  const backToTop = () => {
    window.scrollTo({top: 0,left: 0, behavior:'smooth'})
  }
  return (
    <footer className="">
      
      {/* <IoCaretUpCircle className="backToTop" onClick={backToTop}/> */}
    </footer>
  );
};

export default withRouter(Footer);
