import React, { useState, useEffect } from "react";
import WalletTabMenuList from "./WalletTabMenuList";
import WalletDepositAddress from "./WalletDepositAddress";
import WalletWithdrawal from "./WalletWithdrawal";
import WalletHistory from "./WalletHistory";
import { useTranslation } from "react-i18next";
import axios from "axios";
import WalletPopup from "./WalletPopup";
import History from "./History";
import Preparing from "../../screens/preparing";

const WALLET_SUB_TAB_MENU = [
  {
    id: 1,
    title: "wallet.address",
  },
  {
    id: 2,
    title: "Withdrawal request",
  },
  {
    id: 3,
    title: "Deposit and Withdrawal History",
  },
];

const WalletDepositsWithdrawals = ({ selectedToken }) => {
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
  const { t } = useTranslation();
  const [subNavTab, setSubNavTab] = useState(1);
  const [popup, setPopup] = useState(0);
  const [ethValue, setEthValue] = useState(userinfo.EthValue);
  //console.log("selectedToken: ", selectedToken);
  const [tokenBal, settokenBal] = useState({
    1: userinfo.BtcValue,
    2: userinfo.EthValue,
    3: userinfo.mPandoValue,
    4: userinfo.PandoValue,
  });
  // useEffect(() => {
  //   //console.log("queryEthValue");
  //   let body = {
  //     userID: userinfo.id,
  //   };
  //   axios.post("http://3.35.221.77:7272/queryEthValue", body).then((res) => {
  //     //console.log(res.data.value);
  //     if (res.data.value == 1) {
  //       //console.log("계좌생성");
  //       //console.log("res.data.ethValue>>>>>>>>>>>>>>>", res.data.ethValue);
  //       setEthValue(res.data.ethValue);
  //     } else {
  //       //console.log("실패실패");
  //     }
  //   });
  // }, []);

  // const queryEthValue = () => {

  // };

  useEffect(() => {
    // const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
    //console.log("변경된 ethValue: ", ethValue);
  }, [ethValue]);

  const checkTabBody = (subNavTab) => {
    switch (subNavTab) {
      case 1:
        return <WalletDepositAddress selectedToken={selectedToken} />;
      case 2:
        return selectedToken.id === 3 ? (
          alert(t("Under construction"))
        ) : (
          <WalletWithdrawal
            setPopup={setPopup}
            popup={popup}
            selectedToken={selectedToken}
          />
        );
      case 3:
        return <WalletHistory setPopup={setPopup} popup={popup} />;
      default:
        return;
    }
  };

  // useEffect(() => {
  //   if (selectedToken) {
  //     setSubNavTab(1);
  //   }
  // }, []);
  // return(
  //   <Preparing type='list'/>
  // )
  return (
    <>
      {subNavTab ? (
        <>
          <div className="flex justify-between text-13 border-b border-solid border-black/0.125 p-2 ">
            <h6>{t("holding quantity")}</h6>
            <div>
              {localStorage.getItem('lfitVal')} LFIT
            </div>
          </div>

          {/* <div className="flex justify-between text-13 border-b border-solid border-black/0.125 p-2">
            <h6>{t("Trade waiting")}</h6>
            <div></div>
          </div> */}

          <ul className="flex justify-center items-center bg-black/5 border-black/0.125 text-center cursor-pointer">
            {WALLET_SUB_TAB_MENU.map((list) => {
              return (
                <WalletTabMenuList
                  key={list.id}
                  list={list}
                  navTab={subNavTab}
                  setNavTab={setSubNavTab}
                  width="w-1/3"
                />
              );
            })}
          </ul>

          {checkTabBody(subNavTab)}
        </>
      ) : null}

      {popup ? (
        <WalletPopup
          popup={popup}
          setPopup={setPopup}
          selectedToken={selectedToken}
        />
      ) : null}
    </>
  );
};

export default WalletDepositsWithdrawals;
