import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RiLoginBoxLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BsArrowRightCircle } from "react-icons/bs";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { getUser } from "../utils/api";
import $ from "jquery";

const HomeScreen = () => {

  const updateUser = () => {
    const datachk = localStorage.getItem("userinfo");
    if(datachk !== 'undefined' && datachk !== null){
      const userinfo = JSON.parse(localStorage.getItem("userinfo"));
      const data = {
        userPK : userinfo.id,
      }
      getUser(data).then(res => {
        localStorage.setItem("userinfo", JSON.stringify(res.data.user));
      })
    }
  }

  useEffect(() => {
    updateUser();
    updateDday(); 

  }, []);

  function padZero(number) { 
    return number < 10 ? '0' + number : number; 
  } 

  function calculateDday(targetDate) { 
      const now = new Date(); 
      const target = new Date(targetDate); 
      const timeDifference = target - now; 

      if (timeDifference < 0) { 
          return ""; // 목표 날짜가 이미 지난 경우 빈 문자열 반환 
      } 

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); 
      const hours = padZero(Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))); 
      const minutes = padZero(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))); 
      const seconds = padZero(Math.floor((timeDifference % (1000 * 60)) / 1000)); 

      return `${days}일 ${hours}시간 ${minutes}분 ${seconds}초`
  } 

  function updateDday() { 
      const targetDate = "2024-07-31"; 
      const dday = calculateDday(targetDate); 
      if (dday === "") { 
          $('#dday-count').text(""); // 목표 날짜가 지난 경우 아무것도 표시하지 않음 
          clearInterval(interval); // 1초마다 업데이트하는 인터벌 제거 
      } else { 
          $('#dday-count').text(dday); 
      } 
  } 
  const interval = setInterval(updateDday, 1000); // 1초마다 업데이트


  return (
    <Fragment>
      {/* 색션 1 */}
    <div className="bg-home lg:bg-cover bg-no-repeat flex flex-col justify-center items-center relative"  style={{backgroundColor:'#fff', backgroundPosition:'50%', minHeight:'calc(100vh - 64px)', backgroundSize : 'cover'}}>
      {/* <p
        className="text-2xl text-mainColor font-bold px-2 text-center"
        style={{ wordBreak: "keep-all" }}
      >
        {t("Home title")}
      </p>
      <Link
        to="/wallet"
        className="text-mainColor flex items-center space-x-2 py-[6px] px-3 border-2 border-mainColor rounded-[4px] mt-6"
      >
        <span>{t("Get Started")}</span>
        <RiLoginBoxLine className="text-2xl" />
      </Link> */}
      <section className="sec1 flex flex-col-reverse xl:flex-row">
        <h1 className="hide">색션1</h1>
        <div className="sectionInner flex flex-col items-start justify-center" style={{flex:1, maxWidth : '1400px'}}>
          <div className="xl:text-left flex flex-col xl:items-start" style={{width : '100%'}}>

          <p className="mb-10 text-4xl xl:text-7xl" style={{ textAlign : 'left', color: "orange"}}>
            ENCYKL Platform
          </p>

            {/* <p className="text-3xl xl:text-5xl">
            <div className="contHead dDay"> 
              <p className="text-2xl xl:text-5xl" style={{ textAlign : 'left', color: "orange"}}>
                D-day <span style={{color: "skyblue"}} id="dday-count"></span> 
              </p>
              <p className="text-2xl xl:text-7xl" style={{ textAlign : 'left', color: "orange"}}>
                ENCYKL Platform
              </p>
              <br />
            </div>
            Decentralized Trading Service
            </p> */}
            <p className="text-2xl xl:text-5xl">
            Decentralized Trading Service
            </p>
            <p className="mb-10 text-2xl xl:text-5xl">
            forRenewable Energy
            </p>
            <div className="subArea">
              <img src="/images/home/sub1.png" className="subImage" />
              <img src="/images/home/sub2.png" className="subImage" />
              <img src="/images/home/sub3.png" className="subImage" />
            </div>
            {/* <p className="mb-10">
              VIW is the world’s first platform that can issue NFT based<br />
              on personalised IPFS with best security.
            </p> */}
          </div>
          {/* <button className="btn mr-[20px]">mint</button>
          <button className="btn reverse">mint</button> */}
        </div>
        {/* <div className="flex flex-col items-center justify-center" style={{flex : 1}}>
          <img src="/images/back2.png" alt="" width={'100%'}/>
        </div> */}
      </section>
    </div>

    {/* 색션2 */}
    {/* <section className="sec2">
      <h1 className="hide">색션2</h1>
      <div className="sectionInner">
        <h2 className="mb-10">VIW solves current NFT market places’ 3 Big issues</h2>
        <div className="mb-10">
          - When Creator issues NFT in the current NFT market, the digital asset<br/>
          (collection) is stored in the store owned by the market.<br/>
          - Anyone can reproduce content created by others and issue NFTs.<br/>
          - We cannot confirm the authenticity of ownership of NFT traded in the<br/>market.
        </div>
        <div className="flex justify-center flex-col-reverse md:flex-row">
          <div className="self-center text-white text-right mr-0 md:mr-8 w-[90%]">
            <p style={{color:'#ed1b57'}}>02</p>
            VIW can issue its NFT<br />
            as a secure NFT linked<br />
            to watermark<br />
            technology.<br />
          </div>
          <img src="/images/home/about_us.png" alt="" className="w-1/3 md:w-[400px] mx-auto"/>
          <div className="text-white ml-8 text-left w-full">
            <p style={{color:'#ed1b57'}}>01</p>
            VIW can issue NFTs with<br />
            Creator's personal IPFS,<br />
            not with the market's<br />
            IPFS.
          </div>
        </div>
      </div>
    </section> */}

    {/* 색션3 */}
    {/* <section className="sec3">
      <h1 className="hide">색션3</h1>
      <div className="sectionInner">
        <h2 className="mb-10">Supported NFT types by VIW</h2>
        <ul className="flex justify-between mb-28 mt-16 flex-col md:flex-row">
          <li className="w-full md:w-1/3 mb-16 md:mb-0">
            <div className="flex flex-col items-center imgBlock">
              <img src="/images/home/single_collections.svg" alt=""  />
              <span>
                Single<br/>
                Collections
              </span> 
            </div>
            <p>
              Multiple collection is the ERC1155 type what next<br />
              generation multi-token standard developed<br />
              by the engine. It is a faster and more efficient token<br />
              standard for batch token transmission<br />
            </p>
          </li>
          <li className="w-full md:w-1/3 mb-16 md:mb-0">
            <div className="flex flex-col items-center imgBlock">
              <img src="/images/home/multiple_collections.svg" alt="" />
              <span>
              Multiple<br/>
                Collections
              </span> 
            </div>
            <p>
              Single collection is ERC721,<br />
              which is the token standard for NFT of<br />
              Ethereum blockchain
            </p>
          </li>
          <li className="w-full md:w-1/3 mb-16 md:mb-0">
            <div className="flex flex-col items-center imgBlock">
              <img src="/images/home/watermark_collections.svg" alt="" />
              <span>
              Watermark<br/>
              Collections
              </span> 
            </div>
            <p>
              Watermark collection is a new<br />
              technology that proves and<br />
              delivers the value of NFT
            </p>
          </li>
        </ul>

        <h2 className="mb-6">Supported security by VIW</h2>
        <p className="mb-14 max-w-full md:max-w-[50%] mx-auto" style={{fontWeight:'normal', fontSize:15}}>
          In order to increase the value of NFT products, VIW prevents URL hacking of
          metadata and increases NFT value by checking, verifying content providers and
          NFT issuers in real time.
        </p>
        
        <ul className="flex justify-center mb-24 security flex-col md:flex-row">
          <li className="w-full md:w-1/5">
            <img src="/images/home/security1.png" height={50} alt="" />
            Metadata<br />
            URI hacking
          </li>
          <li className="w-full md:w-1/5">
            <img src="/images/home/security2.png" alt="" />
            Contents provider<br />
            unverifiable
          </li>
          <li className="w-full md:w-1/5">
            <img src="/images/home/security3.png" alt="" />
            NFT creator<br />
            unverifiable
          </li>
        </ul> 

        <h2 className="mb-24">Our Partners</h2>
        <ul className="flex partner items-center flex-col md:flex-row">
          <li className="w-full md:w-1/4 mb-8 md:mb-0">
            <img src="/images/home/partner1.png" alt=""/>
          </li>
          <li className="w-full md:w-1/4 mb-8 md:mb-0">
            <img src="/images/home/partner2.png" alt=""/>
          </li>
          <li className="w-full md:w-1/4 mb-8 md:mb-0">
            <img src="/images/home/partner3.png" alt=""/>
          </li>
          <li className="w-full md:w-1/4 mb-8 md:mb-0">
            <img src="/images/home/partner4.jpeg" alt=""/>
          </li>
        </ul> 
      </div>
    </section>*/}
    </Fragment>
  );
};

export default HomeScreen;
