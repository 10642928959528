import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const WalletTokenList = ({
  list,
  setSelectedToken,
  navTab,
  setNavTab,
  userinfo,
  blockToken,
}) => {
  const { t } = useTranslation();
  const handleClick = () => {
    setSelectedToken(list);
    setNavTab(2);
  };
  const [testToken, settestToken] = useState({
    1: userinfo.ethValue,
    2: userinfo.viwValue,
  });

  useEffect(() => {
    setCoin();
  }, []);

  const setCoin = async () => {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'));
    settestToken({
      1: await localStorage.getItem('btcVal'),  
      2: await localStorage.getItem('ethVal'),
      3: await localStorage.getItem('xrpVal'),
      4: await localStorage.getItem('usdtVal'),
      5: await localStorage.getItem('lfitVal'),
      6: await localStorage.getItem('encykl'),
      7: await localStorage.getItem('viwVal'),
      8: await localStorage.getItem('krwVal'),
      9: await localStorage.getItem('point'),
    })
    // console.log('코인 다시 호출 >>>',  localStorage.getItem('encykl'))
  }

  return (
    <div className="flex justify-between items-center border-t border-solid border-black/0.125 py-2">
      <div className="w-2/3 px-3">
        <div>
          <div className="flex items-center">
            <img
              src={`images/token/${list.ticker.toLowerCase()}.svg`}
              alt="Token Icon"
              className="w-8 mr-1"
            />
            <p className="text-13">{list.ticker}</p> 
          </div>
          <div className="text-13">
            {t("balance")} :{" "}
            <span className="font-bold">{testToken[list.id] || 0}</span>
          </div>
        </div>
      </div>
      {/* <div className="w-1/3 flex justify-center items-center text-[#ecd662] text-10">
        <p>{t("wallet.money_move")}</p>
      </div> */}
    </div>
  );
};

export default WalletTokenList;
