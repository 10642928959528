import React, { useEffect, useState } from "react";
import { Token } from "../../data/tokenList";
import WalletTokenList from "./WalletTokenList";
import { useTranslation } from "react-i18next";
import axios from "axios";

const WalletMyAssets = ({ setSelectedToken, navTab, setNavTab }) => {
  const [userinfo, setUserinfo] = useState({...JSON.parse(localStorage.getItem('userinfo'))});
  const { t } = useTranslation();
  const [checkStatus, setcheckStatus] = useState(false);
  const [id, setid] = useState([]);
  const [test, settest] = useState();
  const [search, setSearch] = useState();
  const [blockToken, setBlockToken] = useState({
    1: 0,
    2: 0,
  });

  const changeBlock = (key, value) => {
    setBlockToken((item) => {
      let newBlock = { ...item };
      newBlock[key] = newBlock[key] + value;
      return newBlock;
    });
  };
  const checkboxHandler = (target) => {
    setcheckStatus(!checkStatus);
  };
  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    // let body = {
    //   userID: userinfo.id,
    // };
    // axios.post("http://3.35.221.77:7272/withdrawList", body).then((res) => {
    //   if (res.data.value == 1) {
    //     const dbdata = JSON.parse(res.data.withdrawList);
    //     for (let i = 0; i < dbdata.length; i++) {
    //       if (dbdata[i]["fields"]["hash"] == null) {
    //         const tokenStatus = dbdata[i]["fields"]["TokenStatus"];
    //         const tokenValue = dbdata[i]["fields"]["volume"];
    //         changeBlock(tokenStatus, Number(tokenValue));
    //       }
    //     }
    //   } else {
    //     //console.log("blocked false");
    //   }
    // });
    // if (userinfo.BtcValue !== "0") {
    //   setid((id) => [
    //     ...id,
    //     {
    //       id: 1,
    //       ticker: "BTC",
    //       withdrawalMinimum: 0.001,
    //       withdrawalFee: 0.001,
    //     },
    //   ]);
    // }
    if (userinfo.ethValue !== "0") {
      setid((id) => [
        ...id,
        {
          id: 1,
          ticker: "ETH",
          withdrawalMinimum: 0.01,
          withdrawalFee: 0.01,
        },
      ]);
    }
    if (!userinfo.viwValue !== "0") {
      setid((id) => [
        ...id,
        {
          id: 2,
          ticker: "LFIT",
          withdrawalMinimum: 0.01,
          withdrawalFee: 0.01,
        },
      ]);
    }
    // if (userinfo.mPandoValue !== "0") {
    //   setid((id) => [
    //     ...id,
    //     {
    //       id: 3,
    //       ticker: "MPANDO",
    //       withdrawalMinimum: 10,
    //       withdrawalFee: 400,
    //     },
    //   ]);
    // }
    // if (userinfo.PandoValue !== "0") {
    //   setid((id) => [
    //     ...id,
    //     {
    //       id: 4,
    //       ticker: "PANDO",
    //       withdrawalMinimum: 10,
    //       withdrawalFee: 200,
    //     },
    //   ]);
    // }
  }, []);
  useEffect(() => {
    if (search === "") {
      setSearch(undefined);
      settest();
    }

    Token.map((item) => {
      if (search?.toUpperCase() === item["ticker"]) {
        settest(item);
      }
    });
  }, [search]);

  return (
    <>
      {/* 검색 */}
      {/* <div className="flex">
        <div className="w-2/3 flex justify-between border-r border-solid border-dark/0.125 pr-4">
          <input
            type="text"
            placeholder={t("coin name/symbol search")}
            className="w-11/12 text-13 py-1.5 px-3"
            onChange={searchHandler}
          />
          <button className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              heigt="18"
              fill="currentColor"
              viewBox="0 0 16 16"
              className="text-[#6c757d]"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
            </svg>
          </button>
        </div>
        <div className="w-1/3 flex justify-center items-center">
          <input
            type="checkbox"
            id="balance"
            className="mr-1"
            onChange={checkboxHandler}
          />
          <label htmlFor="balance" className="text-11 text-[#6c757d]">
            {t("only holding coins")}
          </label>
        </div>
      </div> */}
      {!checkStatus && search === undefined ? (
        Token.length ? (
          Token.map((list) => (
            <WalletTokenList
              list={list}
              key={list.id}
              setSelectedToken={setSelectedToken}
              setNavTab={setNavTab}
              userinfo={userinfo}
              blockToken={blockToken}
              navTab={navTab}
            />
          ))
        ) : (
          <div className="w-full flex justify-center items-center text-13 text-[#dc3545] border-t border-solid border-dark/0.125 py-1.5">
            {t("You don't have wallet coin address")}
          </div>
        )
      ) : checkStatus && search === undefined ? (
        id.length ? (
          id.map((list) => (
            <WalletTokenList
              list={list}
              key={list.id}
              setSelectedToken={setSelectedToken}
              setNavTab={setNavTab}
              userinfo={userinfo}
              blockToken={blockToken}
            />
          ))
        ) : (
          <div className="w-full flex justify-center items-center text-13 text-[#dc3545] border-t border-solid border-dark/0.125 py-1.5">
            {t("You don't have wallet coin address")}
          </div>
        )
      ) : !(test === undefined) ? (
        <WalletTokenList
          list={test}
          key={test.id}
          setSelectedToken={setSelectedToken}
          setNavTab={setNavTab}
          userinfo={userinfo}
          blockToken={blockToken}
        />
      ) : (
        <div className="w-full flex justify-center items-center text-13 text-[#dc3545] border-t border-solid border-dark/0.125 py-1.5">
          {t("You don't have wallet coin address")}
        </div>
      )}
    </>
  );
};

export default WalletMyAssets;
