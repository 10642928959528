import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TetherPopup from "./TetherPopup";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Tether = ({navTab}) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    txid : '',
    id : '',
    amount : 0,
    mainNet : ''
  })

  const [popup, setPopup] = useState(0);
  const [clear, setClear] = useState(false)
  const [status, setStatus] = useState('1')
  
  useEffect(()=>{
    (data.id && data.amount && data.txid && data.mainNet) ? setClear(true) : setClear(false)
  },[data])

  const formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleSelect = (e) => {
    setData({
      ...data,
      mainNet : e.target.value
    })
  } 
  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/,/g, '');
    if(!isNaN(inputValue) || inputValue === ''){
      setData({
        ...data,
        amount : formatNumber(inputValue)
      })
    }
  };

  return (
    <>
      <div className="">
        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">USDT 입금정보</h6>
        </div>
        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">USDT(ETH) 입금계좌</h6>
          <CopyToClipboard text={'0x1029120f2ff29387192'}>
            <div style={{position:'relative'}} onClick={()=>{alert(`0x1029120f2ff29387192 복사완료`)}}>
              <p className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2">
                0x1029120f2ff29387192
              </p>
              <div style={{
                position : 'absolute',
                top : 10,
                right : 10,
                cursor : 'pointer'
              }}>
                <img
                  className="w-4 h-4 dark:contrast-0"
                  src="/images/common/copy-solid.svg"
                  alt=""
                />
              </div>
            </div>
          </CopyToClipboard>
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">USDT(TRX) 입금계좌</h6>
          <CopyToClipboard text={'0x1029120f2ff29387192'}>
            <div style={{position:'relative'}} onClick={()=>{alert(`0x1029120f2ff29387192 복사완료`)}}>
              <p className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2">
                0x1029120f2ff29387192
              </p>
              <div style={{
                position : 'absolute',
                top : 10,
                right : 10,
                cursor : 'pointer'
              }}>
                <img
                  className="w-4 h-4 dark:contrast-0"
                  src="/images/common/copy-solid.svg"
                  alt=""
                />
              </div>
            </div>
          </CopyToClipboard>
        </div>

        <div className="border border-solid border-[#dee2e6] my-6 mx-6"></div>
      </div>

      <div className="">
        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">입금자 정보</h6>
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">메인넷</h6>
          <select name="" id="" className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2" onChange={handleSelect}>
            <option value="">메인넷을 선택해주세요</option>
            <option value="1">ETH</option>
            <option value="2">TRX</option>
          </select>
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">TXID</h6>
          <input
            value={data.txid}
            type="text"
            placeholder="TXID"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            onChange={(event) => setData({...data, txid : event.target.value})}
          />
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">아이디</h6>
          <input
            value={data.id}
            type="text"
            placeholder="아이디를 입력해주세요"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            onChange={(event) => setData({...data, id : event.target.value})}
          />
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">USDT 갯수</h6>
          <input
            value={data.amount !== 0 ? data.amount : ''}
            placeholder="Invest Amount"
            type="text"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            onChange={handleChange}
          />
        </div>

        <div className="border border-solid border-[#dee2e6] my-6 mx-6"></div>
      </div>

      <div className="px-3 my-3">
        <p className="text-13 text-[#6c757d] mb-4">
          {t("Renting a Canance account")}
        </p>
        <p className="text-13 text-[#6c757d] mb-4">
          {t("If you rent a real-name verified account")}
        </p>
        <p className="text-13 text-[#6c757d] mb-4">
          {t("Withdrawal may be restricted")}
        </p>
        <div className="flex jusity-space" style={{gap : 10}}>
          <button
            className={`w-full bg-[#1c2335] text-13 text-white rounded-sm ${clear ? 'opacity-100' : 'opacity-60' } py-1.5`}
            onClick={() => {
              setPopup(3)
            }} // onSubmitData()
            disabled={!clear}
          >
            USDT 입금 신청하기
          </button>
          {/* <button
            className={`w-1/2 bg-[#1c2335] text-13 text-white rounded-sm ${clear ? 'opacity-100' : 'opacity-60' } py-1.5`}
            onClick={() => {
              setStatus('2')
              setPopup(3)
            }} // onSubmitData()
            disabled={!clear}
          >
            재투자 신청하기
          </button> */}
        </div>
      </div>
      {popup ? (
        <TetherPopup
          popup={popup}
          setPopup={setPopup}
          amount={data.amount}
          txid={data.txid}
          status={data.mainNet}
          id={data.id}
        />
      ) : null}
    </>
  );
};

export default Tether;
