import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import jsSHA from "jssha";
import { API_URL2 } from "../../utils/api";

const TetherPopup = ({
  popup,
  setPopup,
  txid,
  amount,
  status,
  repurchaseStatus,
  id
}) => {
  const { t } = useTranslation();
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));

  const [OTP, setOTP] = useState('');
  const [OTPInput, setOTPInput] = useState('');
  const [OTPChk, setOTPChk] = useState(false);
  const intervalRef = useRef(null);
  
  const otpCodeHandler = (e) => {
    setOTPInput(e.target.value);
  };

  const [OTPCode, setOTPCode] = useState('');
  
  useEffect(()=>{
    console.log({'userPK': userinfo.id, 'txid' : txid, 'selectToken' : amount.replace(/,/g, ''), 'status' : status,})
    checkOTP();
    // 컴포넌트가 언마운트될 때 인터벌 정리
    return () => clearInterval(intervalRef.current);
  },[])

  const otpComf = () => {
    $.ajax({ 
      url: `${API_URL2}/otpCheck`, 
      type: "POST", 
      data: {'userPK': userinfo.id, 'otpNum' : OTPInput}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        if(result.code === '1'){
          setOTPChk(true)
          postGetTransactions()
        }
        else{
          alert("OTP가 일치하지 않습니다.");
        }
      }, error: function (error) { 
        console.log(error) 
      } 
    })
  };

  const postGetTransactions = () => {
    clearInterval(intervalRef.current)  
    
    OTPChk && $.ajax({ 
      url: `${API_URL2}/getTransactions`, 
      type: "POST", 
      data: {'userPK': userinfo.id, 'txId' : txid, 'selectToken' : amount.replace(/,/g, ''), 'status' : status,}, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        console.log(result) 
        if(result.code === '1'){
          alert('USDT 투자금 임금 신청 완료')
          setPopup(0)
          window.location.reload();
        }
        else if(result.code === '5'){
          alert('미승인 투자 신청건 존재합니다.')
          setPopup(0)
        }
        else if(result.code === '7'){
          alert('재투자 건이 존재합니다.')
          setPopup(0)
        }
        else if(result.code === '9'){
          alert('이미 신청 내역이 존재합니다.')
          setPopup(0)
        }
        else if(result.code === '99'){
          alert('잘못된 요청입니다. 관리자에게 문의하세요')
          setPopup(0)
        }
      }, error: function (error) { 
        console.log(error) 
      } 
    })
  }

  const checkOTP = () => {
    $.ajax({ 
      url: `${API_URL2}/checkOTPCode`, 
      type: "POST", 
      data: { 'userID': userinfo.id }, 
      dataType: "json", 
      async: true, 
      success: function (result) { 
        // console.log(result.otpCode) 
        setOTPCode(result.otpCode)
        intervalRef.current = setInterval(()=>{
          updateOtp(result.otpCode)
        }, 1000); 

      }, error: function (error) { 
        console.log(error) 
      } 
    })

    function updateOtp(secretVal) {
      var key = base32tohex(secretVal);
      var epoch = Math.round(new Date().getTime() / 1000.0);
      var time = leftpad(dec2hex(Math.floor(epoch / 30)), 16, '0');
  
      // updated for jsSHA v2.0.0 - http://caligatio.github.io/jsSHA/
      var shaObj = new jsSHA("SHA-1", "HEX");
      shaObj.setHMACKey(key, "HEX");
      shaObj.update(time);
      var hmac = shaObj.getHMAC("HEX");
  
      if (hmac === 'KEY MUST BE IN BYTE INCREMENTS') {
          $('#hmac').append($('<span/>').addClass('label important').append(hmac));
      } else {
          var offset = hex2dec(hmac.substring(hmac.length - 1));
          var part1 = hmac.substr(0, offset * 2);
          var part2 = hmac.substr(offset * 2, 8);
          var part3 = hmac.substr(offset * 2 + 8, hmac.length - offset);
          if (part1.length > 0) $('#hmac').append($('<span/>').addClass('label label-default').append(part1));
          $('#hmac').append($('<span/>').addClass('label label-primary').append(part2));
          if (part3.length > 0) $('#hmac').append($('<span/>').addClass('label label-default').append(part3));
      }
  
      var otp = (hex2dec(hmac.substr(offset * 2, 8)) & hex2dec('7fffffff')) + '';
      otp = (otp).substr(otp.length - 6, 6);
      setOTP(otp);
      // 운영기에서 지워야함
      // console.log(otp)
    }

  function dec2hex(s) { return (s < 15.5 ? '0' : '') + Math.round(s).toString(16); }
  function hex2dec(s) { return parseInt(s, 16); }

  function base32tohex(base32) {
      var base32chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
      var bits = "";
      var hex = "";
      if (base32 === "" && base32 === "undefined" && base32 === null) {

      } else {
          for (var i = 0; i < base32.length; i++) {
              var val = base32chars.indexOf(base32.charAt(i).toUpperCase());
              bits += leftpad(val.toString(2), 5, '0');
          }

          for (var j = 0; j + 4 <= bits.length; j += 4) {
              var chunk = bits.substr(j, 4);
              hex = hex + parseInt(chunk, 2).toString(16);
          }
          return hex;
      }
  }

  function leftpad(str, len, pad) {
      if (len + 1 >= str.length) {
          str = Array(len + 1 - str.length).join(pad) + str;
      }
      return str;
  }
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[9999]">
      <div
        className="absolute top-0 left-0 w-full h-full bg-[#00000050]"
        onClick={() => setPopup(0)}
      ></div>
      <div className="relative w-[400px] bg-white rounded">
        <div className="flex justify-between border-b border-solid border-black/0.125 p-4">
          <div className="font-bold">
            {popup === 2 ? t("KRWtabMenu1") : repurchaseStatus === '0' ? t("KRWtabMenu2") : 'USDT 입금 신청'}
          </div>
          <button className="w-3" onClick={() => setPopup(0)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill="#6c757d"
            >
              <path d="M376.6 427.5c11.31 13.58 9.484 33.75-4.094 45.06c-5.984 4.984-13.25 7.422-20.47 7.422c-9.172 0-18.27-3.922-24.59-11.52L192 305.1l-135.4 162.5c-6.328 7.594-15.42 11.52-24.59 11.52c-7.219 0-14.48-2.438-20.47-7.422c-13.58-11.31-15.41-31.48-4.094-45.06l142.9-171.5L7.422 84.5C-3.891 70.92-2.063 50.75 11.52 39.44c13.56-11.34 33.73-9.516 45.06 4.094L192 206l135.4-162.5c11.3-13.58 31.48-15.42 45.06-4.094c13.58 11.31 15.41 31.48 4.094 45.06l-142.9 171.5L376.6 427.5z" />
            </svg>
          </button>
        </div>
    
        <form className="p-4">
          <div className="border-b border-solid border-black/0.125">
            <div className="flex justify-between items-center text-13 mb-3">
              <label htmlFor="withdrawalAddress">TXID</label>
              <div className="w-[200px] flex justify-end items-center border border-solid border-black/0.125 p-1">
                <input
                  id="withdrawalAddress"
                  // type="text"
                  disabled="disabled"
                  className="w-full bg-white font-13 text-end focus:outline-none"
                  value={txid}
                />
              </div>
            </div>
            <div className="flex justify-between items-center text-13 mb-3">
              <label htmlFor="withdrawalAddress">아이디</label>
              <div className="w-[200px] flex justify-end items-center border border-solid border-black/0.125 p-1">
                <input
                  id="withdrawalAddress"
                  // type="text"
                  disabled="disabled"
                  className="bg-white font-13 text-end focus:outline-none"
                  value={id}
                />
              </div>
            </div>
            <div className="flex justify-between items-center text-13 mb-3">
              <label htmlFor="withdrawalAddress">USDT 갯수</label>
              <div className="w-[200px] flex justify-end items-center border border-solid border-black/0.125 p-1">
                <input
                  id="withdrawalAddress"
                  // type="text"
                  disabled="disabled"
                  className="bg-white font-13 text-end focus:outline-none"
                  value={amount}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center text-13 my-4">
            <label htmlFor="OTP">OTP input</label>
            <div className="w-[200px] flex justify-end items-center border border-solid border-black/0.125 p-1">
              <input
                id="OTP"
                type="text"
                className="bg-white font-13 text-end focus:outline-none mr-1"
                onChange={otpCodeHandler}
              />
              <div> OTP</div>
            </div>
          </div>
        </form>
        <div className="flex justify-between items-center p-4 pt-0">
          <button
            onClick={() => setPopup(0)}
            className="w-[48%] text-13 text-black border border-solid border-black/0.125 rounded-sm py-2"
          >
            Cancel
          </button>
          <button
            className="w-[48%] text-13 text-white bg-black rounded-sm py-2"
            onClick={otpComf}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default TetherPopup;
