import React, { useState, useEffect } from "react";
import KRWTabMenuList from "./KRWTabMenuList";
import WalletDepositAddress from "./WalletDepositAddress";
import KRWWithdrawal from "./KRWWithdrawal";
import KRWHistory from "./KRWHistory";
import KRWInvestment from "./KRWInvestment";
import { useTranslation } from "react-i18next";
import axios from "axios";
import KRWPopup from "./KRWPopup";
import History from "./History";
import Preparing from "../../screens/preparing";

const WALLET_SUB_TAB_MENU = [
  {
    id: 1,
    title: "KRWtabMenu3",
  },
  {
    id: 2,
    title: "KRWtabMenu4",
  },
];

const Investment = ({navTab}) => {
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
  const { t } = useTranslation();
  const [subNavTab, setSubNavTab] = useState(1);
  const [popup, setPopup] = useState(0);

  const checkTabBody = (subNavTab) => {
    switch (subNavTab) {
      case 1:
        return <KRWInvestment
          setPopup={setPopup}
          popup={popup}
        />;
      case 2:
        return <KRWHistory
            setPopup={setPopup}
            popup={popup}
            navTab={navTab}
            subNavTab={subNavTab}/>;
      default:
        return;
    }
  };

  return (
    <>
      <KRWInvestment
        setPopup={setPopup}
        popup={popup}
      />
    </>
  );
};

export default Investment;
