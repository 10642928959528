import React, { useEffect, useState } from "react";
import i18next from "i18next";

const Language = ({ showSelectLanguage, setShowSelectLanguage }) => {
  const [language, setLanguage] = useState(i18next.language);

  const changeLanguageHandler = (language) => {
    i18next.changeLanguage(language);
    setLanguage(language);
    setShowSelectLanguage(false);
  };
  useEffect(()=>{
    const lang = window.navigator.language.split('-')
    console.log('lang >>>', lang[0])
    i18next.changeLanguage(lang[0] || 'ko');
  },[])

  return (
    <div
      className={`${
        !showSelectLanguage && "hidden"
      } absolute top-8 right-0 w-[215px] py-2 bg-white rounded-sm border border-gray-300`}
    >
      <ul>
        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("en")}
        >
          <img
            src="/images/lang/en.png"
            alt="english"
            className="w-[30px] h-[30px]"
          />
          <span>English</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("ko")}
        >
          <img
            src="/images/lang/ko.png"
            alt="korean"
            className="w-[30px] h-[30px]"
          />
          <span>한국어</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("ja")}
        >
          <img
            src="/images/lang/ja.png"
            alt="japanese"
            className="w-[30px] h-[30px]"
          />
          <span>日本語</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("zh")}
        >
          <img
            src="/images/lang/zh.png"
            alt="chinese"
            className="w-[30px] h-[30px]"
          />
          <span>简体中文</span>
        </li>

        {/* <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("hi")}
        >
          <img
            src="/images/lang/hi.png"
            alt="hindi"
            className="w-[30px] h-[30px]"
          />
          <span>हिन्दी</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("id")}
        >
          <img
            src="/images/lang/id.png"
            alt="indonesian"
            className="w-[30px] h-[30px]"
          />
          <span>Indonesia</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("ms")}
        >
          <img
            src="/images/lang/ms.png"
            alt="malay"
            className="w-[30px] h-[30px]"
          />
          <span>Melayu</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("vi")}
        >
          <img
            src="/images/lang/vi.png"
            alt="Vietnamese"
            className="w-[30px] h-[30px]"
          />
          <span>Tiếng Việt</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("th")}
        >
          <img
            src="/images/lang/th.png"
            alt="thai"
            className="w-[30px] h-[30px]"
          />
          <span>ภาษาไทย</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("es")}
        >
          <img
            src="/images/lang/es.png"
            alt="spanish"
            className="w-[30px] h-[30px]"
          />
          <span>Español</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("pt")}
        >
          <img
            src="/images/lang/pt.png"
            alt="portuguese"
            className="w-[30px] h-[30px]"
          />
          <span>Português</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("de")}
        >
          <img
            src="/images/lang/de.png"
            alt="german"
            className="w-[30px] h-[30px]"
          />
          <span>Deutsch</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("it")}
        >
          <img
            src="/images/lang/it.png"
            alt="italian"
            className="w-[30px] h-[30px]"
          />
          <span>italiano</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("ru")}
        >
          <img
            src="/images/lang/ru.png"
            alt="rusian"
            className="w-[30px] h-[30px]"
          />
          <span>русский</span>
        </li>

        <li
          className="py-1 px-4 text-[#212529] text-sm flex items-center space-x-2 hover:bg-gray-200 active:bg-blue-600 active:text-white select-none"
          onClick={() => changeLanguageHandler("ar")}
        >
          <img
            src="/images/lang/ar.png"
            alt="arabic"
            className="w-[30px] h-[30px]"
          />
          <span>العربية</span>
        </li> */}
      </ul>
    </div>
  );
};

export default Language;
