import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useHistory } from "react-router-dom";
import KRWPopup from "./KRWPopup";
import $ from "jquery";
import { API_URL2 } from "../../utils/api";

const KRWInvestment = ({ selectedToken }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState();
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  const [popup, setPopup] = useState(0);
  const [clear, setClear] = useState(false)
  const [repurchaseStatus, setRepurchaseStatus] = useState('0')
  const userinfo = JSON.parse(window.localStorage.getItem("userinfo"));

  const history = useHistory();

  useEffect(()=>{
    (name && amount && id) ? setClear(true) : setClear(false)
  },[name, amount, id])

  const formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/,/g, ''); // Remove existing commas
    if (!isNaN(inputValue) || inputValue === '') { // Ensure the input is numeric or empty
      setAmount(formatNumber(inputValue)); 
    }
  };


  const amountBtn = [100,300,500,1000,3000,5000]
  return (
    <>
      <div className="">
        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">입금정보</h6>
        </div>
        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">{t("bankName")}</h6>
          <input
            value={'기업은행'}
            type="text"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            readOnly
          />
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">{t("bankAccount")}</h6>
          <input
            value={'222-101367-04-011'}
            type="text"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            readOnly
          />
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">{t("bankUser")}</h6>
          <input
            value={'(주)엔사이클코리아'}
            type="text"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            readOnly
          />
        </div>

        <div className="border border-solid border-[#dee2e6] my-6 mx-6"></div>
      </div>

      <div className="">
        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">입금자 정보</h6>
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">{t("bankUser")}</h6>
          <input
            value={name}
            type="text"
            placeholder="입금자명을 입력해주세요"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            onChange={(event) => setName(event.target.value)}
          />
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">아이디</h6>
          <input
            value={id}
            type="text"
            placeholder="아이디를 입력해주세요"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            onChange={(event) => setId(event.target.value)}
          />
        </div>

        <div className="text-13 m-2 pt-2 mt-3">
          <h6 className="pb-2">{t("InvestAmount")}</h6>
          <div className="flex mb-2" style={{gap : 4, flexWrap : 'wrap'}}>
            {
              amountBtn.map((item, idx) => (
                <div style={{borderRadius : 4, border : '1px solid #ccc', padding : '0.5rem 1rem', cursor:'pointer'}} onClick={()=>setAmount(formatNumber(String(item*10000)))} key={idx}>{item.toLocaleString()}만</div>
              ))
            }
          </div>
          <input
            value={amount}
            placeholder="Invest Amount"
            type="text"
            className="w-full block border border-solid border-[#ced4da] rounded-sm px-3 py-2"
            onChange={handleChange}
            readOnly
          />
        </div>

        <div className="border border-solid border-[#dee2e6] my-6 mx-6"></div>
      </div>

      <div className="px-3 my-3">
        <p className="text-13 text-[#6c757d] mb-4">
          {t("Renting a Canance account")}
        </p>
        <p className="text-13 text-[#6c757d] mb-4">
          {t("If you rent a real-name verified account")}
        </p>
        <p className="text-13 text-[#6c757d] mb-4">
          {t("Withdrawal may be restricted")}
        </p>
        <div className="flex jusity-space" style={{gap : 10}}>
          <button
            className={`w-1/2 bg-[#1c2335] text-13 text-white rounded-sm ${clear ? 'opacity-100' : 'opacity-60' } py-1.5`}
            onClick={() => {
              setRepurchaseStatus('0')
              setPopup(3)
            }} // onSubmitData()
            disabled={!clear}
          >
            {t("requestInvest")}
          </button>
          <button
            className={`w-1/2 bg-[#1c2335] text-13 text-white rounded-sm ${clear ? 'opacity-100' : 'opacity-60' } py-1.5`}
            onClick={() => {
              setRepurchaseStatus('1')
              setPopup(3)
            }} // onSubmitData()
            disabled={!clear}
          >
            재투자 신청하기
          </button>
        </div>
      </div>
      {popup ? (
        <KRWPopup
          popup={popup}
          setPopup={setPopup}
          selectedToken={selectedToken}
          amount={amount}
          name={name}
          repurchaseStatus={repurchaseStatus}
          id={id}
        />
      ) : null}
    </>
  );
};

export default KRWInvestment;
