import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
} from "../constants/userConstant";

export const userLoginInitialState = {
  loading: false,
  error: false,
  message: "",
  isAuthenticated: false,
  user: null,
};

export const userLoginReducer = (state = userLoginInitialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        error: false,
        message: "",
        isAuthenticated: true,
        user: action.payload,
      };
    case USER_LOGIN_FAILURE:
      return {
        loading: false,
        error: true,
        message: action.payload,
        isAuthenticated: false,
        user: null,
      };
    case USER_LOGOUT:
      return {
        ...userLoginInitialState,
      };
    default:
      return state;
  }
};
