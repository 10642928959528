import React from "react";

export default function Preparing(props){
    return(
        <div className="flex flex-col justify-center items-center px-5" 
        style={props.type==='list'?{height:'calc(100vh - 300px)'}:{height:'calc(100vh - 70px)'}}>
            <img src="/images/header/icon.svg" alt="" width={50} className="mb-5"/>
            <div>준비중입니다...</div>
        </div>
    )
}