import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
import logger from "redux-logger";
import reducers from "./reducers";

const initialState = {};

// 디버깅 모드시 logger 사용
// const middlewares = [thunk, logger];

const middlewares = [thunk];

// process.env.NODE_ENV === "development" ? [thunk] :

const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

// process.env.NODE_ENV === "development"
// ? compose(applyMiddleware(...middlewares))
// :

export default createStore(reducers, initialState, enhancer);
