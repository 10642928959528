import React, { Fragment, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import WalletScreen from "./screens/WalletScreen";
import KRWScreen from "./screens/KRWScreen";
import ConvertScreen from "./screens/ConvertScreen";
import ProfileScreen from "./screens/ProfileScreen";
import LoginScreen from "./screens/LoginScreen";
import PrivateComponent from "./components/PrivateComponent";
import RegisterScreen from "./screens/RegisterScreen";
import Reset from "./screens/Reset";
import PasswordChange from "./screens/PasswordChange";
import passwordChangeIncludeOldPassword from "./screens/PasswordChangeIncludeOldPassword";

const App = () => {
  useEffect(()=>{
    console.log('switch')
    // document.querySelector('body').classList.remove('char')
  },[Route])
  return (
    <Fragment>
      <Header />
      <div style={{height:64}}></div>
      <main>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <PrivateComponent exact path="/wallet" component={WalletScreen} />
          <PrivateComponent exact path="/krw" component={KRWScreen} />
          <Route exact path="/convert" component={ConvertScreen} />
          <Route exact path="/mypage" component={ProfileScreen} />
          <PrivateComponent
            exact
            path="/change-password"
            component={passwordChangeIncludeOldPassword}
          />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/signup" component={RegisterScreen} />
          <Route exact path="/password_reset" component={Reset} />
        </Switch>
      </main>
      <Footer />
    </Fragment>
  );
};

export default App;
